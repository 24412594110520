<template>
  <div class="model-main popup-betcar model2-open model2-level">
    <div class="model-inner">
      <div class="model-wrap">
        <div class="pop-up-content">
            <div class="close-btn" @click="toggleCart">
              <span class="icon-close"></span>
            </div>
            <div class="head-top">
              <div class="title-name">
                <b class="titleP">베팅카트</b>
              </div>
            </div><!-- tab -->
            <div class="cart-tab">
                <ul id="tabs-cart" class="tab-list">
                    <li class="active">
                      <a href="javascript:void(0);">베팅카트</a>
                    </li>
                    <!-- <li class="">
                      <a href="javascript:void(0);">베팅 기록</a>
                    </li> -->
                </ul>
            </div>
            <div id="tab-cart" class="tab-content-betcart"><!-- Favorite -->
                <div id="tab10" class="tab-content-cart">
                    <div class="cart-btns">
                        <!-- <button class="refresh">새로고침</button> -->
                        <button class="clear-all">모두 삭제</button>
                    </div>
                    <div class="scroll-panel">
                      <div class="main-content" v-for="(item, index) in cartItem" :key="'cartItem'+index">
                        <ul ><!-- 一個大項-->
                          <li :class="{'pause': item.betsStatus === 'y'}" >
                            <!-- header上方大項目 -->
                              <div class="head-panel">
                                <span v-if="item.betsStatus === 'y'" class="icon-pause" style=""></span>
                                  <div class="info team-name">
                                    <span >{{ item.homeNameKr || item.homeNameEn }}</span>
                                    <span>&nbsp;VS {{ item.awayNameKr || item.awayNameEn }}</span>
                                  </div>
                                <span class="icon-close" @click="deleteItemCart(item)" ></span>
                              </div><!-- main 主要詳細內容 -->
                              <div class="main-panel" ><!-- 一個完整 -->
                                  <div class="item" >
                                    <div class="list game-type" >
                                      <span >{{ item.marketNameKr || item.marketNameEn }}</span>
                                      <span class="org-odds" v-if="item.betsPrevOdds !== item.odds">{{ item.betsPrevOdds }}</span>
                                    </div>
                                    <div class="list" >
                                      <span >{{ item | cartBetsName }}</span>
                                      <span class="odds">{{ item.odds }}</span>
                                    </div>
                                  </div>
                              </div>
                          </li>
                        </ul>
                      </div>
                        <div class="main-content bet-panel">
                            <ul>
                              <li><!-- header上方大項目 -->
                                <div class="head-panel">
                                  <div class="info">
                                    <span>선택폴더</span>
                                  </div>
                                  <div class="total-amount">{{ cartItem.length }} 폴더</div>
                                </div><!-- 詳細內容 -->
                                <div class="main-panel">
                                  <div class="item">
                                    <div class="list">
                                      <span>배당율 합계</span>
                                      <span class="odds">{{ odds }}</span>
                                    </div>
                                    <div class="list">
                                      <span>당첨 예상금액</span>
                                      <span class="odds win">{{ winnings | makeComma }} 원</span>
                                    </div>
                                    <div class="list">
                                      <span>베팅 금액</span>
                                      <input type="text" class="" placeholder="금액을 입력해주세요." disabled="" v-model="amount">
                                    </div>
                                  </div>
                                </div>
                              </li>
                                <li class="num-keyboard-panel">
                                    <div class="amount-btns">
                                        <div class="as-btns" @click="addCalculate(1)">
                                          <span>1</span>
                                        </div>
                                        <div class="as-btns" @click="addCalculate(2)">
                                          <span>2</span>
                                        </div>
                                        <div class="as-btns" @click="addCalculate(3)">
                                          <span>3</span>
                                        </div>
                                        <div class="as-btns" @click="addCalculate(4)">
                                          <span>4</span>
                                        </div>
                                        <div class="as-btns" @click="addCalculate(5)">
                                          <span>5</span>
                                        </div>
                                        <div class="as-btns" @click="addCalculate(6)">
                                          <span>6</span>
                                        </div>
                                        <div class="as-btns" @click="addCalculate(7)">
                                          <span>7</span>
                                        </div>
                                        <div class="as-btns" @click="addCalculate(8)">
                                          <span>8</span>
                                        </div>
                                        <div class="as-btns" @click="addCalculate(9)">
                                          <span>9</span>
                                        </div>
                                        <div class="as-btns" @click="maxBet">
                                          <span>MAX</span>
                                        </div>
                                        <div class="as-btns" @click="addCalculate(0)">
                                          <span>0</span>
                                        </div>
                                        <div class="as-btns" @click="delCalculate">
                                          <img src="@/assets/images/key-delet.png" alt="">
                                        </div>
                                    </div>
                                    <div class="change-btns">
                                      <button v-if="!editable" class="" @click="addAmount(setOne)">{{setOne | makeComma}}</button>
                                      <button v-if="!editable" class=""  @click="addAmount(setTwo)">{{setTwo | makeComma}}</button>
                                      <button v-if="!editable" class=""  @click="addAmount(setThree)">{{setThree | makeComma}}</button>
                                      <input v-if="editable" v-model="setOne" type="number" class="" placeholder="금액을 입력해주세요.">
                                      <input v-if="editable" v-model="setTwo" type="number" class="" placeholder="금액을 입력해주세요.">
                                      <input v-if="editable" v-model="setThree" type="number" class="" placeholder="금액을 입력해주세요.">
                                      <!-- <input type="text" onfocus="this.value=''" value="1,000,000" /> -->
                                      <button class="edit-btn" @click="editable = !editable">
                                          <div v-if="!editable">수정</div>
                                          <div v-else>저장</div>
                                      </button>
                                    </div>
                                    <p class="hint-txt" style="display: none;">배당, 베팅항목 및 유효성이 변경되었습니다</p>
                                </li>
                                <li class="num-keyboard-panel btns">
                                    <button class="cancel" @click="resetAmount">금액리셋</button>
                                    <button class="submit" v-if="!isLoading" @click="processBet">
                                      <img src="@/assets/images/bet-check.png">
                                      베팅하기
                                    </button>
                                    <button class="submit" v-else>
                                      <img src="@/assets/images/loading.gif">
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="total-box-h">
                            <div class="total-list">
                              <span>최소 베팅금액</span>
                              <b class="point">{{ minAmount | makeComma }} 원</b>
                            </div>
                            <div class="total-list">
                              <span>최대 베팅금액</span>
                              <b>{{ maxAmount | makeComma }} 원</b>
                        </div>
                      </div>
                        <div class="cart-status-bar error"
                              style="display: none;"><p><img
                                src="/assets/animation_waiting.c9998f51.gif" alt=""
                                style="display: none;"><img
                                src="/assets/icon-success.0b3bf1a1.svg" alt=""
                                style="display: none;"><img src="/assets/icon-error.f0c2208e.svg"
                                                            alt=""></p><span
                                class="icon-arrow-right"></span></div>
                        <div class="spaceBar">&nbsp;</div>
                    </div>
                </div>
            </div><!-- 종합 TODO: -->
            <div id="tab11" class="tab-content-cart" style="display: none;">
                <div class="scroll-panel history-panel">
                    <div class="main-content">
                        <ul><!-- 一個大項--></ul>
                    </div>
                    <div class="spaceBar">&nbsp;</div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import MSG from "@/contants/msg";
export default {
  name: "EuropeanCart",
  props: ['toggleCart'],
  data: function(){
    return {
      winnings: 0,
      amount: 0,
      maxOddsAmount: 0,
      limitWinnings: 0,
      maxAmount: 5000000,
      isLoading: false,
      setOne: 10000,
      setTwo: 50000,
      setThree: 100000,
      editable: false,
    }
  },
  computed:{
    odds: function(){
      return Number(this.$store.getters["SPORT_TOTAL_ODDS"])
    },
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"] || {}
    },
    minAmount: function(){
      return Number(this.levelSetting.prematchEuropeanSetting.minBetting) || 0;
    },
    maxWinAmount: function(){
      return Number(this.levelSetting.prematchEuropeanSetting.manyOther.maxWin) || 0;
    },
    maxOneFolderAmount: function(){
      return Number(this.levelSetting.prematchEuropeanSetting.soloOther.maxBetting) || 0;
    },
    maxOneFolderWinAmount: function(){
      return Number(this.levelSetting.prematchEuropeanSetting.soloOther.maxWin) || 0;
    },
    maxOdds: function(){
      return this.levelSetting.prematchEuropeanSetting.maxOdds || 0;
    },
    oneFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.one || 0;
    },
    twoFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.two || 0;
    },
    threeFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.three || 0;
    },
    fourFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.four || 0;
    },
    fiveFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.five || 0;
    },
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.sports.cart,
    }),
  },
  mounted() {
    this.setMaxAmount();
  },
  watch:{
    // 배당변경시 자동으로 최대 가능 베팅금액 변경
    odds: function (val){
      if (Number(this.maxOdds) <= Number(val)) {
        this.cartItem.pop();
        return alert( '최대 가능 배당을 초과했습니다.');
      }
      this.calWinnings();
    },
    // 베팅금액 초과시 자동으로 최대 가능 베팅금액 변경
    amount: {
      handler(val){
        if (this.maxAmount < Number(val)) {
          this.amount = this.maxAmount;
          return alert( '베팅금액은 최대베팅금액을 초과할 수 없습니다.');
        }
        if (this.user.members_cash < Number(val)) {
          this.amount = this.user.members_cash;
          return alert( '베팅금액은 보유금액을 초과할 수 없습니다.');
        }
        this.calWinnings();
        // this.setMaxAmount();
      },
      immediate: true,
    },
    cartItem: {
      handler: function(){
        this.setMaxAmount();
        this.calWinnings();
      },
      immediate: true,
      deep: true,
    },
    // 당첨금 변동
    winnings: function(val){
      if (Number(val) > this.limitWinnings){
        this.amount = Math.floor(this.limitWinnings / this.odds);
        return alert( `당첨금액은 ${this.$options.filters.makeComma(this.limitWinnings)}을 초과할 수 없습니다.`);
      }
    }
  },
  methods: {
    deleteItemCart: function(obj){
      this.$store.dispatch('DELETE_CART_ITEM',obj)
    },
    maxBet: function(){
      const values = [Math.floor(this.limitWinnings/this.odds), this.maxAmount, this.user.members_cash]
      this.amount = Math.min.apply(null, values)
      // 보유금액 * 배당이 limitWinnings에 못미칠때
      // if (this.winnings < this.limitWinnings){
      //   this.amount = Math.floor(this.limitWinnings / this.odds);
      // }
    },
    processBet: function(){
      let data = {};
      if (this.amount < this.minAmount) return alert( `최소 베팅금은 ${this.minAmount}원 입니다.`);
      if (this.cartItem.find(b => Number(b.odds) <= 1 )) return alert( '1 배당이 포함되어 베팅이 불가합니다.');
      if (this.odds > this.maxOdds) return alert( '최대 배당을 초과하여 베팅할 수 없습니다.')
      if (this.maxAmount < this.amount) {
        return alert( '베팅이 가능한 금액을 초과했습니다.');
      }

      // 유저설정 우선
      if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 1 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.one) < 0){
        let confirmMsg = `단폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.one}% 배당 하락이 발생합니다.`;
        const oneConfirm = confirm(confirmMsg);
        if (!oneConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.one, '유저설정-단폴베팅')
      } else {
        if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 1 && this.oneFolderDeduction) {
          let confirmMsg = `단폴 베팅시 ${this.oneFolderDeduction}% 배당 하락이 발생합니다.`;
          const singleConfirm = confirm(confirmMsg);
          if (!singleConfirm) return;
          this.singleBetDeduction()
        }
      }

      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 2 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.two) < 0
      ){
        let confirmMsg = `투폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.two}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.two, '유저설정-투폴베팅')
      }
      else {
        if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 2 && this.twoFolderDeduction){
          let confirmMsg = `투폴 베팅시 ${this.twoFolderDeduction}% 배당 하락이 발생합니다.`;
          const doubleConfirm = confirm(confirmMsg);
          if (!doubleConfirm) return;
          this.doubleBetDeduction()
        }
      }
      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 3 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.three) < 0
      ){
        let confirmMsg = `세폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.three}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.three, '유저설정-세폴베팅')
      }

      console.log(this.user.is_discount_three_over, this.threeFolderDeduction)
      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length > 3 &&
          this.user.is_discount_three_over === 'Y'
      ){
        let confirmMsg = `세폴 베팅 초과시 ${this.user.members_detail_setting.베팅차감.스포츠.three}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.three, '유저설정-세폴초과베팅')
      }

      data.bettings = this.cartItem;
      data.amount = this.amount;
      data.totalOdds = this.odds;
      data.winnings = this.winnings;
      this.$store.dispatch('SET_PROCESS', data);

      if (this.cartItem.find(b => b.betsStatus === 'y')) return alert( MSG.error.notAvailableBet);
      this.isLoading = true;
      this.$store.dispatch('PROCESS_EUROPEAN_BETTING')
      .then(()=> {
        this.isLoading = false;
        alert('베팅에 성공했습니다.')
      }).catch((err) => {
        console.log(err);
        this.isLoading = false;
        alert('베팅에 실패했습니다.')
      })
      //다폴더

      this.amount = 0;
      this.$store.dispatch('ALL_CLEAR_ITEM');
    },
    betDeduction:function(penalty, reason){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = penalty;
        this.cartItem[c].penaltyReason = reason;
      }
    },
    singleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.oneFolderDeduction;
        this.cartItem[c].penaltyReason = '단폴 베팅';
      }
    },
    doubleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.twoFolderDeduction;
        this.cartItem[c].penaltyReason = '투폴 베팅';
      }
    },
    tripleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = '0.04';
        this.cartItem[c].penaltyReason = '세폴이상 베팅';
      }
    },
    calWinnings: function (){
      this.winnings = Math.floor(this.amount * this.odds);
    },
    setMaxAmount: function(){
      if (!this.user) {
        this.limitWinnings = 0;
      }
      let max = Number(this.levelSetting.prematchEuropeanSetting.maxBetting);

      if (this.cartItem.length === 1) {
        this.limitWinnings = this.maxOneFolderWinAmount;
        max = this.cartItem[0].maxAmount;
      }
      if (this.cartItem.length > 1) {
        this.limitWinnings = this.maxWinAmount;
        max = Math.min.apply(Math, this.cartItem.map(function(o) { return o.maxAmount; }))
      }
      this.maxAmount = max;
      if (Number(this.amount) > Number(max)) {
        if (max > this.user.members_cash) this.amount = this.user.members_cash
        else this.amount = max;
      }
    },
    addAmount(amt){
      this.amount = Number(this.amount) + Number(amt);
    },
    resetAmount(){
      this.amount = 0;
    },
    addCalculate(num) {
      this.amount = Number(String(this.amount) + String(num));
    },
    delCalculate() {
      this.amount = String(this.amount).slice(0, -1);
      if (this.amount === '') this.amount = 0;
      this.amount = Number(this.amount);
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
