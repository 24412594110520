import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store/index';
// import MSG from '../contants/msg';
import Layout from '../Layout.vue' // 스포츠 베팅
import MiniLayout from '../MiniLayout.vue' // 스포츠 베팅

import { InplayList, EuropeanList, KoreanList, ResultList } from './sports' // 스포츠 베팅
import { Baccarat1, OddEven } from './minigame' // 스포츠 베팅


// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(() => {
//     // console.log('RELOAD location >> ', location)
//     return window.location.reload();
//   });
// };
Vue.use(VueRouter)


// 라우팅 정의
const routes = [
  {
    path: "/sport",
    name: "스포츠-모바일",
    component: Layout,
    meta: { requireAuth: false },
    children: [
      {
        path: "inplay",
        name: "스포츠-인플레이(벳컨)",
        component: InplayList,
        meta: { requireAuth: false },
      },
      {
        path: "european",
        name: "스포츠-유럽형(벳컨)",
        component: EuropeanList,
        meta: { requireAuth: false },
      },
      {
        path: "korean",
        name: "스포츠-한국형(벳컨)",
        component: KoreanList,
        meta: { requireAuth: false },
      },
      {
        path: "result",
        name: "경기결과",
        component: ResultList,
        meta: { requireAuth: false },
      },
    ],
  },
  {
    path: "/mini",
    name: "미니게임-모바일",
    component: MiniLayout,
    meta: { requireAuth: false },
    children: [
      {
        path: "lotus/baccarat1",
        name: "로투스-바카라1",
        component: Baccarat1,
        meta: { requireAuth: false },
      },
      {
        path: "lotus/oddeven",
        name: "로투스-홀짝",
        component: OddEven,
        meta: { requireAuth: false },
      },
    ],
  },

  { path: "/", redirect: "/european" },
  { path: "*", redirect: "/inplay" },
];



const router = new VueRouter({
  // mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});


// router.beforeEach((to, from, next) => {
//   next();
// });

export default router
