<template>
<div class="">
  <template v-if="originList.length === 0">
    <div>
      <p class="noData" style="">
        <img src="@/assets/images/noData-img.png" alt="no-data">
        <b>데이터 없음</b>
        <span>Sorry, Check no data</span>
      </p>
    </div>
  </template>
  <template v-else>
    <template v-for="league in sortByLeagues(originList)">
      <div class="sport-live-box" :key="'league'+league.id">
        <div class="sport-live-panel">
          <div class="sport-items"><!-- 一組聯賽 -->
            <ul class="sport-group">
              <li class="">
                <div class="head-league sport-live-select">
                  <!-- <div class="leagues" v-if="game.folders[0]">{{ game.folders[0].market | marketName }}</div> -->
                </div>
                <a class="sport-trigger">
                  <span class="logo sp-tab-soccer"></span>
                    <img v-if="league.icon" height="20" width="20" :src="league.icon">
                    <p class="for-livesport"> {{ league.location | locationName }} &nbsp;>&nbsp; {{ league | leagueName }}</p>
                </a>
                <div class="sport-content" style="display: block;"  v-for="game in originList.filter((game) => String(game.leagueId) === String(league.id))" :key="'gameId' + game.id">
                  <div class="o-list"><!-- 一場比賽 start-->
                    <ul class="bet-panel live-panel">
                      <div class="left-box">
                          <div class="team-box">
                              <!-- <div class="favorite">
                                <span class="icon-star"></span>
                              </div> -->
                              <div class="logo l-logo">
                                <div class="name">{{ game.homeTeam | teamName }}</div>
                                <div class="logo-pic">
                                  <img src="@/assets/images/NoTeam.png">
                                </div>
                              </div>
                              <div class="vs">
                                  <div class="time-txt hide">08/24
                                      17:00
                                  </div>
                                  <div class="score-box">
                                      <div class="scoreArea">
                                        <span class="score">
                                          <div class="score-num-box"
                                              style="font-size: 1rem; height: 1rem; line-height: 1rem;">
                                              <div class="score-num-item">
                                                <div class="M-Slider down">
                                                  <div class="digital front">{{ game.homeResult }}</div>
                                                  <div class="digital back">{{ game.homeResult }}</div>
                                                </div>
                                              </div>
                                            </div>
                                        </span>
                                      </div>
                                      <div class="scoreArea">
                                        <span>:</span>
                                      </div>
                                      <div class="scoreArea">
                                        <span class="score">
                                          <div class="score-num-box" style="font-size: 1rem; height: 1rem; line-height: 1rem;">
                                            <div class="score-num-item">
                                              <div class="M-Slider down">
                                                <div class="digital front">{{ game.awayResult }}</div>
                                                <div class="digital back">{{ game.awayResult }}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </span>
                                      </div>
                                  </div>
                                  <div class="time-txt">
                                    <span class="part">{{ game | currentPeriod3('period')}}</span>
                                      <!-- <span>
                                        <span>07:11</span>
                                      </span> -->
                                  </div>
                              </div>
                              <div class="logo r-logo">
                                  <div class="name">{{ game.awayTeam | teamName }}</div>
                                  <div class="logo-pic">
                                    <img src="@/assets/images/NoTeam.png">
                                  </div>
                              </div>
                          </div><!--v-if-->
                          <template v-if="game.folders[0] && game.folders[0].isVisible === 'y'">
                            <DetailPrice v-for="bet in game.folders[0].bets" :add-cart="addCart" :check-cart="checkCart" :bets="bet" :game="game" :folder="game.folders[0]"></DetailPrice>
                          </template>
                          <template v-else>
                            <li class="l-team"><!-- 隊伍名稱 --><div class="team-name">{{ game.homeTeam | teamName }}</div><!-- 隊伍名稱 --><div class="odds-panel"><div class="arrow-type-box"><span class="arrow up hide"></span><span class="bet-type one hide"></span></div><div class="odd">-</div></div></li>
                            <li class="c-team"><div class="odds-panel"><div class="odd">-</div></div></li>
                            <li class="r-team"><div class="team-name">{{ game.awayTeam | teamName }}</div><div class="odds-panel"><div class="arrow-type-box"><span class="arrow down hide"></span><span class="bet-type one hide"></span></div><div class="odd">-</div></div></li>
                          </template>
                      </div>
                      <div class="right-box">
                        <div class="team-box">
                            <div class="icon-type">
                              <span class="icon-live-football"></span>
                            </div>
                        </div>
                        <a @click="selectGame(game)">
                          <div class="all-bet">
                            <span>{{ game.foldersCnt.cnt}}</span>
                            <span class="icon-arrow-right"></span>
                          </div>
                        </a>
                      </div>
                    </ul><!-- 一場比賽 end-->
                  </div>
                </div>
              </li>
            </ul><!-- 一組聯賽 -->
          </div>
        </div>
      </div>
    </template>
  </template>
</div>
</template>

<script>
import DetailPrice from './DetailPrice'

import {mapState} from "vuex";

export default {
  name: "InpList",
  components: {
    DetailPrice,
  },
  props: ['originList', 'addCart', 'checkCart', 'checkBlockGame', 'blockTypeSetting', 'selectGame', 'selectedGame'],
  computed: {
    lists: function () {
      return this.originList
    },
    ...mapState({
      linkedGame: state => state.inplay.inplayLink,
      user: state => state.user.user,
    }),
  },
  data: function () {
    return {
      openDetailGame: [],
    }
  },
  mounted: function () {
    this.$socket.client.on('inplayStats', this.matchStats)
    this.$socket.client.on('inplayMatchUpdate', this.matchUpdate)
    this.$socket.client.on('inplay', this.updateData)
  },
  methods: {
    sortBySports: function (lists) {
      const sports = lists.map(e => e.sport);
      const arrUnique = sports.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.seq === character.seq) === idx
      });
      arrUnique.sort()
      return arrUnique
    },

    sortByLeagues: function (lists) {
      const leagues = lists.map(e => e.league);
      const arrUnique = leagues.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },

    // 매치 상태 변경
    matchStats: function (data) {
      const results = JSON.parse(data);
      // console.log(results, '매치점수변경');
      for (const result of results) {
        const game = this.lists.find(e => String(e.id) === String(result.EventId))
        if (game) {
          const score = result.Score;
          if (score) {
            game.homeResult = score.split(':')[0] || 0;
            game.awayResult = score.split(':')[1] || 0;
            if (!result.IsTimeout) game.currentPeriod = result.Period;
          }
        }
      }
    },
    // 매치 상태 업데이트
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      for (const matchData of results) {
        const game = this.originList.find(e => String(e.id) === String(matchData.Id));
        if (game) {
          const cartItem = this.$store.state.inplay.cart.find(e => String(e.gameId) === String(matchData.Id));
          if (cartItem) {
            cartItem.betsStatus = matchData.IsSuspended || !matchData.IsVisible ? 'y' : 'n';
            cartItem.betsUpdated = true;
          }
          if (matchData.IsStarted) game.status = '진행';
          game.isVisible = matchData.IsVisible ? 'y' : 'n';
          game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          if (matchData.MatchStatus === 2) {
            game.status = '종료';
            setTimeout(() => {
              this.removeGames(game.seq)
            }, 3000)
          }
        }
      }
    },
    removeGames: function (gameSeq) {
      if (this.lists && this.lists.length > 0) {
        let index = this.lists.indexOf(e => e.seq === gameSeq)
        if (index >= 0) {
          this.lists.splice(index, 1)
        }
      }

      this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_SEQ', gameSeq);
    },

    // 배당 변경
    updateData: function (data) {
      const result = JSON.parse(data);
      // console.log(result, '배당변경')
      this.$store.dispatch('UPDATE_INPLAY_LIST', result);
    },
    addStaredGame: function (gameSeq) {
      this.$store.dispatch('TOGGLE_STARED', gameSeq);
    },
    /**
     * 토글 액션
     * */
    toggleDetail: function (id) {
      this.toggle(this.openDetailGame, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailGame.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailGame = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    /*
    * 종목의 자세히 버튼
    * **/
    checkArray: function (id) {
      let result = this.openDetailGame.indexOf(id) >= 0
      return result
    },
  },
}
</script>

<style scoped>

</style>
