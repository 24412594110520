<template>
<div class="">
  <template v-for="(date, index) in this.uniqGameStartTime">
    <template v-for="league in timeLeagueSort(date)" >
      <div class="sport-live-box" :key="'league'+league + index">
        <div class="sport-live-panel">
          <div class="sport-items"><!-- 一組聯賽 -->
            <ul class="sport-group">
              <li class="">
                <div class="head-league sport-live-select">
                  <!-- <div class="leagues" v-if="game.folders[0]">{{ game.folders[0].market | marketName }}</div> -->
                </div>
                <a class="sport-trigger">
                  <span class="logo sp-tab-soccer"></span>
                  <img v-if="lists.find(e => e.leagueId === league).sport.icon" height="20" width="20" :src="lists.find(e => e.leagueId === league).sport.icon">
                  <img v-if="lists.find(e => e.leagueId === league).icon" height="20" width="20" :src="lists.find(e => e.leagueId === league).icon">
                  <p class="for-livesport"> {{ lists.find(e => e.leagueId === league).location | locationName }} &nbsp;>&nbsp; {{ lists.find(e => e.leagueId === league).league | leagueName}}</p>
                </a>
                <div class="status-bar" style="margin-bottom: 5px;"><div class="time-info" ><p class="time" >{{ date | formatDate('MM-DD HH:mm')}}</p></div></div>
                <div class="sport-content" style="display: block;"  v-for="game in gameTimeLeagueSort(date, league)" :key="'gameId' + game.id">
                  <div class="o-list"><!-- 一場比賽 start-->
                    <template v-for="(folder, index) in game.folders">
                      <transition name="fade">
                        <ul class="bet-panel live-panel" v-show="index === 0 || checkArray(game.id)">
                          <div class="left-box">
                            <DetailPrice v-for="bet in folder.bets" :add-cart="addCart" :check-cart="checkCart" :bets="bet" :game="game" :folder="folder"></DetailPrice>
                          </div>
                          <div class="right-box" v-if="index === 0">
                            <a @click="toggleDetail(game.id)">
                              <div class="all-bet">
                                <span>{{ game.folders.length }}</span>
                                <span class="icon-arrow-right"></span>
                              </div>
                            </a>
                          </div>
                      </ul>
                      </transition>
                    </template>
                  </div>
                </div>
              </li>
            </ul><!-- 一組聯賽 -->
          </div>
        </div>
      </div>
    </template>
  </template>
</div>
</template>

<script>
import DetailPrice from './DetailPrice'
// import Date from './Date'

import {mapState} from "vuex";

export default {
  name: "KoreanCenterList",
  components: {
    DetailPrice
    // Date,
  },
  props: ['originList', 'addCart', 'checkCart'],
  computed: {
    lists: function () {
      return this.originList
    },
    uniqGameStartTime: function(){
      const dates = this.originList.map(e => e.startTime);
      const time = this.$options.filters.uniq(dates)
      return time;
    },
    ...mapState({
      user: state => state.user.user,
    }),
  },
  data: function () {
    return {
      openDetailGame: [],
    }
  },
  mounted: function () {
    this.lists = this.originList;
  },
  methods: {
    timeLeagueSort: function(date){
      const games = this.lists.filter(e => e.startTime === date);
      const leagues = games.map(e => e.leagueId);
      const leaguesId = this.$options.filters.uniq(leagues);
      return leaguesId;
    },
    gameTimeLeagueSort: function(date, league){
      const games = this.lists.filter(e => e.startTime === date && e.leagueId === league);
      return games;
    },
    sortByLeagues: function (lists) {
      const leagues = lists.map(e => e.league);
      const arrUnique = leagues.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    /**
     * 토글 액션
     * */
    toggleDetail: function (id) {
      this.toggle(this.openDetailGame, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailGame.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailGame = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    /*
    * 종목의 자세히 버튼
    * **/
    checkArray: function (id) {
      let result = this.openDetailGame.indexOf(id) >= 0
      return result
    },
  },
}
</script>

<style scoped>
ul.live-panel {
  margin: 0 !important;
  padding: 1px !important;
}
.status-bar {
    width: 100%;
    padding: 0 4px;
    position: relative;
    top: 5px;
    border-bottom: 0px;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.status-bar .time-info {
    min-width: 20vw;
    padding: 1px 4px;
}

.status-bar p.time {
    transform: scale(.95);
    -webkit-transform: scale(.95);
    -moz-transform: scale(.95);
    -ms-transform: scale(.95);
    -o-transform: scale(.95);
}
</style>
