import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './helper/filters'
import './helper/plugins'


import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import VueCookies from "vue-cookies";
import Vuetify from 'vuetify'
// import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false




Vue.use(VueCookies);
Vue.use(Vuetify);

const SOCKET = io(process.env.VUE_APP_SOCKET_URL, {
  reconnection: true,
  reconnectionDelay: 2000,
});

Vue.use(VueSocketIOExt, SOCKET);


new Vue({
  router,
  store,
  // created(){
  //   // created lifecycle hook 생성
  //   const userString = localStorage.getItem('token');
  //   if (userString) {
  //     const userData = JSON.parse(userString);
  //     this.$store.commit('ME', userData);
  //   }
  // },
  render: h => h(App),
}).$mount('#app')




