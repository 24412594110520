<template>
  <!-- <td class="soprts_in_acc_2 "
      :style="[width === 2 ? {'width': '50%'} : {'width': '33%'}]"
      :class="{'sel': checkCart(bets)}"
      @click="addCart(bets, folder, game)"
  >
    <div class="sports_v_l_1">
      {{ teamName }}
    </div>
    <div class="sports_v_r_1">
      <i v-if="priceStatus === 'up'" class="ico-up blink"></i>
      <i v-if="priceStatus === 'down'" class="ico-down blink"></i>
      <span class="font_002 ">
        {{ bets.price }}
      </span>
      <i v-if="priceStatus" class="sel_old ">({{ startPrice }})</i>
    </div>
  </td> -->
  <li class="l-team" v-if="folder.isVisible === 'y'" :class="{'on': checkCart(bets), 'w50': folder.bets.length === 2}" @click="addCart(bets, folder, game)">
    <div class="team-name">{{ teamName }}</div>
    <div class="odds-panel">
        <div class="arrow-type-box"></div>
        <div class="odd" :class="{'up':priceStatus === 'up', 'down': priceStatus === 'down' }"><span class=""></span>{{ bets.price }}</div>
    </div>
    
    <div v-if="priceStatus === 'up'" class="icon-arrow-odd up fade"
          style="display: block;"></div>
    <div v-if="priceStatus === 'down'" class="icon-arrow-odd down fade"
          style="display: block;"></div>
  </li>
  <li v-else class="l-team" :class="{'on': checkCart(bets)}">
    <div class="team-name">{{ teamName }}</div>
    <div class="odds-panel">
        <div class="arrow-type-box"></div>
        <div class="odd"><span class=""></span>{{ bets.price }}</div>
    </div>
  </li>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game'],
  data: function(){
    return {
      startPrice: null,
      priceStatus: null,
    }
  },
  computed: {
    type: function(){
      let type = 'home';
      if (this.bets.name === 'W1' || this.bets.name === 'Home' || this.bets.name === 'Over' || this.bets.name === '1X' || this.bets.name === 'Odd' || this.bets.name === 'Yes') return 'home';
      if (this.bets.name === 'X' || this.bets.name === '12') return 'draw';
      if (this.bets.name === 'W2' || this.bets.name === 'Away' || this.bets.name === 'Under' || this.bets.name === 'X2' || this.bets.name === 'Odd' || this.bets.name === 'No') return 'away';
      return type
    },
    price: function(){
      return Number(this.bets.price).toFixed(2)
    },
    teamName: function(){
      let name = this.bets.name;
      if (this.bets.name.includes('W1')) name = name.replace('W1', `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn}`);
      if (this.bets.name.includes('W2')) name = name.replace('W2', `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn}`);
      if (this.bets.name === 'Home') return `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} (${this.bets.line})` ;
      if (this.bets.name === 'Away') return `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} (${this.bets.line})` ;
      if (this.bets.name === 'Over') return `오버 ${this.bets.line}` ;
      if (this.bets.name === 'Under') return `언더 ${this.bets.line}`;
      if (this.bets.name.includes('And')) name = name.replace('And',' && ');
      if (this.bets.name.includes('and')) name = name.replace('and',' && ');
      if (this.bets.name.includes('1X')) name = name.replace('1X', '1팀승 또는 무승부');
      if (this.bets.name.includes('X2')) name = name.replace('X2', '무승부 또는 2팀승');
      if (this.bets.name.includes('12')) name = name.replace('12', '1팀승 또는 2팀승');
      if (this.bets.name.includes('X')) name = name.replace('X','무');
      if (this.bets.name === '12') name = '1팀승 또는 2팀승';
      if (this.bets.name === 'X2') name = '무승부 또는 2팀승';
      if (this.bets.name === 'Odd') name = '홀';
      if (this.bets.name === 'Even') name = '짝';
      if (this.bets.name === 'Yes') name = '예';
      if (this.bets.name === 'No') name = '아니오';
      if (this.bets.name === 'NoGoal') name = '노골';
      if (this.bets.name.includes('ormore')) name = name.replace('ormore',' 골 이상');
      if (this.folder.market.isHandicap === 'y') name += ` (${this.bets.line})`
      return name;
    },
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.priceStatus = 'down';
      if (Number(newVal) - Number(val) > 0) this.priceStatus = 'up';
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    priceStatus: function(){
      setTimeout(() => {
        this.priceStatus = null
        this.startPrice = null;
      } ,5000)
    },
  },
}
</script>

<style scoped>
.w50 {
  width: 49% !important;
}
</style>
