<template>
  <div class="tabZone">
    <div class="tabFrame">
      <ul >
        <ul id="tabs-sport" class="tab-type" style="padding-bottom: 0px !important;">
          <li  :class="{'active': payload.date.indexOf(row.REG_DT) >= 0}" @click="filterDo(row.REG_DT)" v-for="(row, index) in date" :key="'europeanDate'+index">
            <a class="tab-name"  v-if="index === 0">오늘경기<!--<span>({{ dateSplit(row.REG_DT) }})</span>--></a>
            <a class="tab-name"  v-else>{{ dateSplit(row.REG_DT) }}</a>
          </li>
        </ul>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Date",
  props: ['dateCnt', 'initFolder', 'payload'],
  computed: {
    date: function(){
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dateCnt.sort((a, b) => {
        if (a.REG_DT > b.REG_DT) return 1;
        else if (b.REG_DT > a.REG_DT) return -1;
        else return 0;
      })
      return this.dateCnt;
    }
  },
  created() {
    if (this.date[0]?.cnt === '0'){
      this.dateCnt.splice(0, 1);
      this.filterDo(this.dateCnt[0].REG_DT)
    }
  },
  methods: {
    dateSplit(date){
      const result = date.slice(3, 8)
      return result
    },
    filterDo: function (date) {
      this.payload.date = '20' + date
      this.initFolder(this.payload)
    },
  },
}
</script>

<style scoped>
.row-detail-list-move {
  transition: transform 1s;
}

.row-detail-list {
  transition: transform 1s;
}
</style>
