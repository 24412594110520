<template>
  <div id="tab-minigame-content" class="tab-content-box">
      <div id="tc1" class="tab-content-inner content-for-pb" style="">
          <div class="lock-black" style="display: block;" v-if="(!game || gameStatus) && selectType === 'betting'">
              <div class="lock-icon">
                  <div class="loading-icon"></div>
              </div>
              <div class="txt">베팅시간이 초과되어 베팅을 할수 없습니다.</div>
          </div>
          <div class="m-bet-box" v-if="selectType === 'betting'">
            <div class="list m-list-even">
                <div class="m-title">
                  <p>바카라1</p>
                </div>
                <div class="m-list-btn">
                    <button class="item w3" style="width: 25% !important;" :class="{'active': checkCart('홀')}" @click="addCart('Player')">
                        <div class="icon btn-blue">
                          <span>Player</span>
                        </div>
                        <div class="odd">
                          <span>1.95</span>
                          <span class="coinbet-type">0k</span>
                        </div>
                    </button>
                    <button class="item w3" style="width: 25% !important;" :class="{'active': checkCart('홀')}" @click="addCart('Tie')">
                        <div class="icon btn-blue">
                          <span>Tie</span>
                        </div>
                        <div class="odd">
                          <span>1.95</span>
                          <span class="coinbet-type">0k</span>
                        </div>
                    </button>
                    <button class="item w3" style="width: 25% !important;" :class="{'active': checkCart('짝')}" @click="addCart('Banker')">
                        <div class="icon btn-red">
                          <span>Banker</span>
                        </div>
                        <div class="odd">
                          <span>1.95</span>
                          <span class="coinbet-type">0k</span>
                        </div>
                    </button>
                </div>
            </div>
          </div>
          <div class="m-bet-box" v-else>
              <div class="titles">배팅 내역</div>
              <!-- <div class="date-filter-list">
                  <button class="date"><span>9월</span><span>7일</span>
                  </button>
                  <button class="date"><span>9월</span><span>8일</span>
                  </button>
                  <button class="date"><span>9월</span><span>9일</span>
                  </button>
                  <button class="date active"><span>9월</span><span
                  >10일</span></button>
              </div> -->
              <div class="m-content-box">
                  <div class="d-list" v-for="(row, index) in lists" :key="'betOddEvenResult' + index">
                      <div class="d-item">
                        <span >시간：</span>
                        <b>{{ row[0].betting_regdatetime | formatDate('YYYY-MM-DD') }}</b>
                      </div>
                      <div class="d-item">
                        <span >배팅금：</span>
                        <b >{{ row[0].betting_bet_amount | makeComma }}</b>
                      </div>
                      <div  class="d-item"><span >배당：</span><b >{{ row[0].betting_odds }}</b>
                      </div>
                      <div  class="d-item">
                        <span >상세내역：</span><b >{{ row[0].betting_bet_name }}</b>
                      </div>
                      <div class="d-item">
                        <span>당첨금：</span><b>{{ row[0].betting_total_result | bettingStatus }}</b></div>
                  </div>

              </div>
          </div>
          
          <div class="total-box">
              <div class="title-top">
                  <div class="item">
                    <p>분류<span></span></p>
                  </div>
                  <div class="item">
                    <p>선택<span></span></p>
                  </div>
                  <div class="item">
                    <p>
                      배당<span class="odd">{{ odds }}</span>
                    </p>
                  </div>
              </div>
              <div class="bet-button-box">
                  <div class="input-list">
                    <span>베팅 금액</span>
                      <div class="input"><input v-model="amount"> 원</div>
                  </div>
                  <div class="input-list">
                    <span>예상 수익률</span>
                      <div class="input">
                        <input type="text" readonly="" v-model="winnings"> 원
                      </div>
                  </div>
                  <div class="amount-box">
                      <button class="btn-signup-common bet-all-button" @click="maxBet">올인
                      </button>
                      <button class="btn-cancel-common reset-button" @click="resetAmount">초기화
                      </button>
                      <button class="btn-submit-common submit-button" @click="processBet">배팅하기
                      </button> 
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import {mapState} from "vuex";

export default {
  props: ['game', 'getGame', 'reqData', 'selectType', 'lists'],
  name: "Baccarat1Cart",
  data: function(){
    return {
      winnings: 0,
      amount: 10000,
      maxOddsAmount: 0,
      gameStatus: false,
    }
  },
  computed:{
    odds: function(){
      return Number(this.$store.getters["LOTUS_TOTAL_ODDS"])
    },
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"]
    },
    minAmount: function(){
      return Number(this.levelSetting.lotusSetting.minBetting);
    },
    maxAmount: function(){
      return Number(this.levelSetting.lotusSetting.maxBetting);
    },
    maxOneFolderWinAmount: function(){
      return Number(this.levelSetting.lotusSetting.soloOther.maxBetting);
    },
    maxOdds: function(){
      return this.levelSetting.lotusSetting.maxOdds;
    },
    limitWinnings: function(){
      return this.levelSetting.lotusSetting.maxWin;
    },
    endTime: function(){
      return this.$store.state.setting.settings.config.option.gameEndtime.find(e => e.gameName === '로투스').endTime
    },
    lotusSetting: function(){
      return this.$store.getters['LOTUS_SETTING']
    },
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.lotus.cart,
    }),
  },
  watch:{
    // 배당변경시 자동으로 최대 가능 베팅금액 변경
    odds: function (val){
      if (Number(this.maxOdds) <= Number(val)) {
        this.cartItem.pop();
        return alert('최대 가능 배당을 초과했습니다.');
      }
      this.calWinnings();
    },
    // 베팅금액 초과시 자동으로 최대 가능 베팅금액 변경
    amount: {
      handler(val){
        if (this.game){
          if (this.maxAmount < Number(val)) {
            this.amount = this.maxAmount;
            return alert('베팅금액은 최대베팅금액을 초과할 수 없습니다.')
          }
          if (this.user.members_cash < Number(val)) {
            this.amount = this.user.members_cash;
            return alert('베팅금액은 보유금액을 초과할 수 없습니다.')
          }
        }
        this.calWinnings();
      },
      immediate: true,
    },
    cartItem: {
      handler: function(){
        this.calWinnings();
      },
      immediate: true,
      deep: true,
    },
    // 당첨금 변동
    winnings: function(val){
      if (Number(val) > this.limitWinnings){
        this.amount = Math.floor(this.limitWinnings / this.odds);
        alert(`당첨금액은 ${this.$options.filters.makeComma(this.limitWinnings)}을 초과할 수 없습니다.`)
      }
    }
  },
  mounted() {
    this.interval = setInterval(this.inter, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    inter: function(){
      this.gameStatus = this.gameChangeStatus();
      this.gameStarted()
    },
    gameChangeStatus: function(){
      if (this.game){
        const now = moment().unix()
        const startTime = moment(this.game.game_starttime).subtract(9, 'hours').unix()
        if (this.endTime < startTime - now) {
          return false;
        } else {
          this.$store.dispatch('CLEAR_LOTUS');
          return true
        }
      }
      return false;
    },
    gameStarted: function(){
      if (this.game){
        const now = moment().unix();
        const startTime = moment(this.game.game_starttime).subtract(9, 'hours').unix();
        if (startTime - now <= 0 && startTime - now > -2) {
          this.getGame();
          setTimeout(this.reqData, 3000);
        }
      } else {
        this.getGame()
      }
    },
    changeAmount: function(amt){
      this.amount += amt;
    },
    processBet: function(){
      let data = {};
      if (this.amount < this.minAmount) return alert(`최소 베팅금은 ${this.minAmount}원 입니다.`);
      if (this.cartItem.find(e => e.startTime < moment().subtract(9, "hours").format('YYYY-MM-DD HH:mm:ss'))) return alert('이미 시작한 경기입니다.')
      data.bettings = this.cartItem;
      if (data.bettings.length === 0) {
        return alert(`베팅항목을 선택해주세요.`);
      }
      data.amount = this.amount;
      data.totalOdds = this.odds;
      data.winnings = this.winnings;
      this.$store.dispatch('SET_PROCESS', data);
      this.$store.dispatch('SHOW_MODAL', 'bettingLotusProcess')
      setTimeout(this.reqData, 4000);

      this.amount = 0;
      this.clearCart();
    },
    calWinnings: function (){
      this.winnings = Math.floor(this.amount * this.odds);
    },
    resetAmount: function(){
      this.amount = 10000;
    },
    halfBet: function(){
      // 보유금액 * 배당이 limitWinnings에 못미칠때
      // 보유금액 전부
      if (this.user.members_cash < this.maxAmount / 2){
        this.amount = this.user.members_cash;
      }
      // 보유금액 * 배당 limitWinnings에 넘을때
      // limitWinnings 근사치에 베팅
      // 베팅금액을 조정
      if (this.user.members_cash  > this.maxAmount / 2){
        this.amount = Math.floor(this.maxAmount / 2)
      }
    },
    maxBet: function(){
      // 보유금액 * 배당이 limitWinnings에 못미칠때
      // 보유금액 전부
      if (this.user.members_cash < this.maxAmount){
        this.amount = this.user.members_cash;
      }
      // 보유금액 * 배당 limitWinnings에 넘을때
      // limitWinnings 근사치에 베팅
      // 베팅금액을 조정
      if (this.user.members_cash > this.maxAmount){
        this.amount = this.maxAmount;
      }
    },
    addCart: function (type) {
      this.$store.dispatch('ADD_BET_LOTUS', {
        gameId: this.game.game_id,
        betsSeq: this.game.folders[0].bets.find(e=> e.bets_name === type).bets_seq,
        betStatus: this.game.folders[0].bets.find(e=> e.bets_name === type).bets_status,
        betsId: this.game.folders[0].bets.find(e=> e.bets_name === type).bets_id,
        betsName: this.game.folders[0].bets.find(e=> e.bets_name === type).bets_name,
        sportsId: null,
        marketsId: null,
        foldersSeq: this.game.folders[0].folders_seq,
        foldersType: this.game.folders[0].folders_type,
        odds: this.lotusSetting.baccarat1.odds[type.toLowerCase()],
        line: null,
        leagueNameKr: '로투스',
        leagueNameEn: '로투스',
        leagueNameIcon: '로투스',
        sportNameKr: '로투스',
        sportNameEn: '로투스',
        sportNameIcon: '로투스',
        homeNameKr: '로투스',
        homeNameEn: '로투스',
        awayNameKr: '로투스',
        awayNameEn: '로투스',
        marketNameKr: '바카라1',
        marketNameEn: '바카라1',
        startTime: this.game.game_starttime,
        odds1: null,
        odds2: null,
        odds3: null,
        betType: '로투스',
        detailType: '바카라1',
        currentPeriod: null,
        currentTime: null,
        homeScore: null,
        awayScore: null,
        round: this.game.game_round,
      })
    },
    clearCart: function(){
      this.$store.dispatch('CLEAR_LOTUS')
    },
    checkCart: function(type) {
      return this.cartItem.filter(e => e.betsName === type).length > 0
    },
    addAmount(amount){
      this.amount += amount;
    }
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
