<template>
<div class="model-main popup-betcar model-open">
    <section id="out-wrapper"><!-- Header 登入前＋後-->
      <header class="PageDetail">
          <div class="wrapper">
            <a class="icon-back" @click="selectGame(null)">
              <span class="icon-arrow-back"></span>
            </a>
            <a class="details-title">
              {{ game.homeTeam | teamName }} VS {{ game.awayTeam | teamName }}
            </a>
            <div class="icon-type">
              <!-- <span class="on icon-live-baseball"></span> -->
            </div>
          </div>
      </header><!-- Header 登入前＋後--><!-- Sport Total -->
      <section id="content" class="sporttotalheight">
        <div class="wrapsport">
          <div class="wrapper"><!-- Live Video -->
              <ul id="tabs-sport" class="tab-type tab-total">
                  <li class="active">
                    <a>
                      <div class="tab-name">전체</div>
                    </a>
                  </li>
                  <li class=""><a>
                      <div class="tab-name">매치</div>
                  </a></li>
                  <li class=""><a>
                      <div class="tab-name">이닝</div>
                  </a></li>
              </ul><!-- tab內容 -->
              <div id="tab-sport" class="tab-content-box sport-box sport-total"><!-- 全部 -->
                  <div id="tab1" class="tab-content">
                      <div class="sport-total-box">
                          <div class="sport-eu-panel">
                              <div class="sport-items"><!--v-if-->
                                <teamplate v-if="!selectedMarket">
                                  <template v-for="row in marketSort(originList)">
                                    <template v-if="row.nameEn?.indexOf('{sw}') >= 0">
                                      <template v-for="period in uniqPeriod(row.id)" >
                                        <ul class="sport-group sport-total no-reverse">
                                          <li class="">
                                            <span class="favorite">
                                              <span class="icon-star"></span></span>
                                              <a class="sport-trigger">
                                                <p lass="sp-tab-baseball">
                                                  <template v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                                                    {{ row | marketName(period) }}
                                                  </template>
                                                  <template v-else>
                                                    {{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}
                                                  </template>
                                                </p>
                                                <img src="/assets/pay-Instant.47a2970f.png">
                                              </a>
                                              <div class="sport-content" style="display: block;">
                                                <template
                                                    v-for="row2 in originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === String(period))"
                                                  >
                                                  <ul class="bet-panel total-panel" :class="{'two-list': row2.bets.length === 2}">
                                                    <DetailPrice  v-for="bet in row2.bets" :bets="bet" :folder="row2" :check-cart="checkCart" :game="game" :key="'betRpice'+bet.id" :add-cart="addCart"></DetailPrice>
                                                  </ul>
                                                </template>
                                              </div>
                                          </li>
                                        </ul>
                                      </template>
                                    </template>
                                    <template v-else>
                                      <ul class="sport-group sport-total no-reverse">
                                          <li class="">
                                            <span class="favorite">
                                              <span class="icon-star"></span></span>
                                              <a class="sport-trigger">
                                                <p lass="sp-tab-baseball">
                                                  <template v-if="row.nameEn.indexOf('Team 1') < 0 && row.nameEn.indexOf('Team 2') < 0 ">
                                                    {{ row | marketName(period) }}
                                                  </template>
                                                  <template v-else>
                                                    {{ row | teamMarketNamePeriod(game.homeTeam, game.awayTeam, period) }}
                                                  </template>
                                                </p>
                                                <!-- <img src="/assets/pay-Instant.47a2970f.png"> -->
                                              </a>
                                              <div class="sport-content" style="display: block;">
                                                <template
                                                    v-for="row2 in originList.filter(e => String(e.marketId) === String(row.id) && String(e.periodSequence) === '0')"
                                                  >
                                                  <ul class="bet-panel total-panel" :class="{'two-list': row2.bets.length === 2}">
                                                    <DetailPrice  v-for="bet in row2.bets" :bets="bet" :folder="row2" :check-cart="checkCart" :game="game" :key="'betRpice'+bet.id" :add-cart="addCart"></DetailPrice>
                                                  </ul>
                                                </template>
                                              </div>
                                          </li>
                                        </ul>
                                    </template>
                                  </template>
                                </teamplate>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </section><!-- Sport Total -->
    </section>
  </div>
</template>

<script>
// import Loading from '@/components/Loading';
import DetailPrice from './DetailPrice'
// import DetailLock from './DetailLock'
// import DetailCorrectScoreLock from './DetailCorrectScoreLock'
// import DetailCorrectScorePrice from './DetailCorrectScorePrice'
import {mapState} from "vuex";

export default {
  name: "CenterDetail",
  components: {
    DetailPrice,
    // DetailLock,
    // DetailCorrectScoreLock,
    // DetailCorrectScorePrice,
    // Loading,
  },
  props: ['game', 'addCart', 'checkCart', 'checkBlockGame', 'blockTypeSetting', 'selectGame'],
  data: function () {
    return {
      // game: null,
      originList: null,
      lists: null,
      isLoading: false,
      selectedMarket: null,
      closed: [],
      timeCheck: true,
      scoreCheck: true,
    }
  },
  mounted() {
    this.lists = this.originList;
    this.initFolder();
    this.interval = setInterval(this.initFolder2, 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  watch: {
    selectedMarket: function (val) {
      if (!val) return this.lists = this.originList;
      if (val === '승무패') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11557, 11558, 11575, 11649, 11930].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11603, 11604, 11611].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 3) return [11531, 11537, 11667].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11521, 11544].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11622, 11629, 11625].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [11515, 11507, 12972, 13103].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12240, 12242, 12243, 12244, 12245, 12246, 16817, 16818].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '핸디캡') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11562, 11563, 11580, 11752, 11968].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11608, 11615, 11615, 11615].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 3) return [11532, 11539, 11668].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11904, 11724, 11546].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11623, 11631, 11626].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [11516, 11509, 17055].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12775, 12786, 12797, 12808, 12823].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === '오버언더') {
        this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11559, 11560, 11561, 11577, 11753, 11969, 11592, 11586].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11605, 11607, 11606, 11612, 11612, 11612, 11613, 11613, 11613, 11614, 11614, 11614].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 3) return [11533, 11534, 11535, 11662, 11663, 11664, 11669].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11726, 11723, 11720, 12104, 11545].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [11512, 11510, 11511, 13655, 11508, 17056, 13106].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12651, 12776, 12787, 12798, 12809, 12824].indexOf(e.marketSeq) >= 0
        });
      }

      if (val === 'ETC') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11573, 11574, 11923, 12930, 11684, 11685, 11567, 11572, 11568, 11727, 13619, 11732].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11652, 11653, 11654, 11658, 11659, 11656, 11717, 11718].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 3) return [11536, 16637, 12530, 12531, 12566, 12567, 12568, 13558, 14049].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11522, 11540, 11543, 11675, 12105].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [13102, 13101].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12646, 12648, 12653, 12812, 12827, 12777, 12788, 12799, 12813, 12828, 12645, 12647, 12652, 12810, 12825, 12780, 12791, 12802, 12816, 12831, 12778, 12789, 12800, 12814, 12829, 12782, 12793, 12804, 12818, 12833, 12783, 12794, 12805, 12819, 12834, 12784, 12795, 12806, 12820, 12835].indexOf(e.marketSeq) >= 0
        });
      }


      if (val === 'STAR') {
        this.lists = [];
        this.stared.map(s => {
          const lists = this.originList.filter(l => String(s.gameId) === String(this.game.id) && String(s.seq) === String(l.marketSeq) && String(s.periodSequence) === String(l.periodSequence))
          lists.map(folder => this.lists.push(folder));
        })

        return;
      }

      this.lists = this.originList.filter(e => e.market.nameKr?.indexOf(val) >= 0);
    },
    game:{
      handler: function(val, newVal){
        //경기 시간 변동체크
        if (val && newVal && val.id !== newVal.id) {
          this.originList = [];
          this.initFolder();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initFolder: function () {
      const payload = {
        gameId: this.game.id
      }
      return this.$store.dispatch('GET_DETAIL', { payload })
          .then(data => {
            const result = data.data;
            if (!result.payload.game) this.$router.push({ name: '스포츠-유럽형(벳컨)'});
            this.originList = result.payload.list;
            this.lists = this.originList;
            this.isLoading = true;
            this.selectMarket(this.selectedMarket)
          }).catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })
    },
    /**
     * 토글 액션
     * */
    marketSort: function (folders) {
      const markets = folders.map(e => e.market);
      const arrUnique = markets.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      this.openDetailBet.splice(index, 1);
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    uniqPeriod: function (marketId) {
      const periods = this.originList.filter(e => String(e.marketId) === String(marketId)).map(e => e.periodSequence);
      console.log(periods)
      let set = new Set(periods);
      const uniqueArr = [...set];
      uniqueArr.sort()
      return uniqueArr
    },
    arrLenDiv: function (arr, div = 4) {
      return Math.floor(arr.length / div) + 1
    },
    // 매치 상태 변경
    matchStats: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        if (String(result.EventId) === String(this.game.id)) {
          const score = result.Score;
          // console.log('stats', result);
          // console.log(score, 'stats');
          this.game.homeResult = score.split(':')[0] || 0;
          this.game.awayResult = score.split(':')[1] || 0;
          if (result.EventType !== 'period' && !result.IsTimeout) this.game.currentPeriod = result.Period;
          if (result.Set1Score) this.game.set1Score = result.Set1Score;
          if (result.Set2Score) this.game.set2Score = result.Set2Score;
          if (result.Set3Score) this.game.set3Score = result.Set3Score;
          if (result.Set4Score) this.game.set4Score = result.Set4Score;
          if (result.Set5Score) this.game.set5Score = result.Set5Score;
          if (result.Set6Score) this.game.set6Score = result.Set6Score;
          if (result.Set7Score) this.game.set7Score = result.Set7Score;
          if (result.Set8Score) this.game.set8Score = result.Set8Score;
          if (result.Set9Score) this.game.set9Score = result.Set9Score;
          // console.log(result.RemainingTime)
          if (result.RemainingTime) {
            this.game.remainTime = result.RemainingTime;
            setTimeout(()=>{
              this.game.remainTime = null;
            }, 5000)
          }
          if (result.Info) this.game.subResult = result.Info
        }
      }
    },
    // 매치 상태 업데이트
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      // console.log(results, 'match')
      for (const r in results) {
        const matchData = results[r];
        if (String(this.game.id) === String(matchData.Id)) {
          if (matchData.IsStarted) this.game.status = '진행';
          this.game.isVisible = matchData.IsVisible ? 'y' : 'n';
          this.game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          if (matchData.MatchStatus === 2){
            this.game.status = '종료';
          }
        }
      }
    },
    // 인플레이 경기 자세히 내에 해당 폴더 변경
    updateData: function (data) {
      const result = JSON.parse(data);
      const gameMarket = result.filter(e => String(e.MatchId) === String(this.game.id))
      if (gameMarket.length > 0) {
        if (this.game){
          gameMarket.map(m => {
            if (this.originList){
              const folder = this.originList.find(e => String(e.id) === String(m.Id));
              if (folder) {
                folder.isVisible = m.IsVisible ? 'y' : 'n';
                folder.isSuspended = m.IsSuspended ? 'y' : 'n';
                m.Selections.map(s => {
                  const bet = folder.bets.find(b => String(b.id) === String(s.Id));
                  if (bet) {
                    bet.price = s.Price;
                    bet.isVisible = s.IsVisible ? 'y' : 'n';
                    bet.isSuspended = s.IsSuspended ? 'y' : 'n';
                  }
                })
              }
            }

            if (this.lists){
              const folder2 = this.lists.find(e => String(e.id) === String(m.Id));
              if (folder2) {
                folder2.isVisible = m.IsVisible ? 'y' : 'n';
                folder2.isSuspended = m.IsSuspended ? 'y' : 'n';
                m.Selections.map(s => {
                  const bet = folder2.bets.find(b => String(b.id) === String(s.Id));
                  if (bet) {
                    bet.price = s.Price;
                    bet.isVisible = s.IsVisible ? 'y' : 'n';
                    bet.isSuspended = s.IsSuspended ? 'y' : 'n';
                  }
                })
              }
            }
          })
        }
      }
    },
    folderClosed: function (seq, period) {
      const data = {
        seq,
        period
      };
      const index = this.closed.findIndex(e => e.seq === seq && e.period === period)
      if (index >= 0) return this.closed.splice(index, 1);
      this.closed.push(data);
    },
    selectMarket: function (market) {
      this.selectedMarket = market;
    },
    matchStats2: function(data){
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        // console.log(result);
        if (String(result.EventId) === String(this.game.id)) {
          const score = result.Score;
          // console.log('stats2', result);
          this.game.homeResult = score.split(':')[0] || 0;
          this.game.awayResult = score.split(':')[1] || 0;
          if (result.EventType !== 'Finished' && result.EventType !== 'period' && !result.IsTimeout) this.game.currentPeriod = result.Period;
          if (result.Set1Score) this.game.set1Score = result.Set1Score;
          if (result.Set2Score) this.game.set2Score = result.Set2Score;
          if (result.Set3Score) this.game.set3Score = result.Set3Score;
          if (result.Set4Score) this.game.set4Score = result.Set4Score;
          if (result.Set5Score) this.game.set5Score = result.Set5Score;
          if (result.Set6Score) this.game.set6Score = result.Set6Score;
          if (result.Set7Score) this.game.set7Score = result.Set7Score;
          if (result.Set8Score) this.game.set8Score = result.Set8Score;
          if (result.Set9Score) this.game.set9Score = result.Set9Score;
          if (result.RemainingTime) this.game.remainTime = result.RemainingTime;
          if (result.DangerousAttackScore) this.game.dangerousAttack = result.DangerousAttackScore;
          if (result.ShotOnTargetScore) this.game.shotOnTargetScore = result.ShotOnTargetScore;
          if (result.ShotOffTargetScore) this.game.shotOffTargetScore = result.ShotOffTargetScore;
          if (result.Info) this.game.subResult = result.Info
        }
      }
    },
    // 스포츠별 피어리드별 타임 체크
    sportPeriodCheck: function (period) {
      if (this.game.sportSeq === 3 && Number(period) >= 4) return false;
      return true;
    },
  },
}
</script>

<style scoped>
ul.total-panel {
  padding: 0 !important;
}
</style>

