<template>
  <div class="tabZone">
    <div class="tabFrame">
      <ul>
        <ul id="tabs-sport" class="tab-type">
          <!-- <li :class="{'active': !selectedSport }" @click="selectSport(null)">
            <a>
              <span class="g-amount">{{ originList.length }}</span>
              <div class="tab-logo sp-tab-favorite">
              </div>
              <div class="tab-name">전체</div>
            </a>
          </li> -->
          <li :class="{'active': String(sport.id) === String(selectedSport) }" v-for="(sport, sportIndex) in sports" :key="'sport' + sportIndex"  @click="selectSport(String(sport.id))">
            <a>
              <span class="g-amount">{{ originList.filter((game) => String(game.sportId) === String(sport.id)).length}}</span>
              <div class="tab-logo sp-tab-favorite">
                <img v-if="sport.icon" :src="sport.icon" alt="">
              </div>
              <div class="tab-name">{{ sport | sportName }}</div>
            </a>
          </li>
        </ul>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightSide",
  props: ['filterByGames', 'originList'],
  data: function(){
    return {
      selectedSport: null,
    }
  },
  computed: {
    sports: function () {
      const sports = this.originList.map(e => e.sport);
      const arrUnique = sports.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort((a, b) => a.id - b.id)
      return arrUnique
    },
  },
  methods: {
    selectSport: function (id) {
      console.log(id);
      this.filterByGames('sport', id)
      this.selectedSport = id;
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
