<template>
<div class="main_content_continer" version="1.1.114">
    <section id="content" class="">
      <div class="wrapsport">
        <ul class="tab-list">
            <li class="active">
              <router-link :to="{ name: '스포츠-인플레이(벳컨)'}">인플레이</router-link>
            </li>
            <li class="">
              <router-link :to="{ name: '스포츠-유럽형(벳컨)'}">유럽형</router-link>
            </li>
            <li class="">
              <router-link :to="{ name: '스포츠-한국형(벳컨)'}">한국형</router-link>
            </li>
        </ul>
        <div class="wrapper" id="wrapper-hide">
          <!-- 퀵베팅 -->
          <div class="odds-setting-panel" style="display: none;">
            <div class="odds-setting-content">
              <div class="control-box">
                <div class="switch-content">
                  <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" disabled="">
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">퀵베팅</span>
                  </label>
                  <p class="icon-quick-betrule quickbet-btn"></p>
                  <div class="stick-on hide">
                    <div class="on">
                      <p>베팅금: 1,000</p>
                      <button class="confirm-edit">수정</button>
                    </div>
                    <div class="edit" style="display: none;">
                      <input type="text" placeholder="" disabled="">
                      <button class="confirm-amount">확인</button>
                    </div>
                  </div>
                </div>
                <a href="javascript: void(0)" class="icon-setting"></a>
                <div class="alarm odd-alarm hide">
                  <span class="icon-alarm"></span>
                <div>금액 수정후 베팅해주세요(단폴만 가능)</div>
                  </div>
              </div>
            </div>
          </div>
          <div class="odds-change-panel" style="display: none;">
              <div class="head">
                <p>배당율이 변경될 경우 :</p>
                <span class="icon-close close-change-panel"></span>
              </div>
              <div class="container">
                <div class="radio">
                  <input type="radio" id="radio-0" name="movement" value="AlwaysAsk">
                  <label for="radio-0" class="radio-label">항상 물어보기</label>
                </div>
                <div class="radio">
                  <input type="radio" id="radio-1" name="movement" value="HigherOdds">
                  <label for="radio-1" class="radio-label">더 높은 배당율로 수락</label>
                </div>
                <div class="radio">
                  <input type="radio" id="radio-2" name="movement" value="AnyOdds">
                  <label for="radio-2" class="radio-label">모든 배당율로 수락</label>
                </div>
              </div>
          </div>
          <SportZone :originList="originList" :filter-by-games="filterByGames"></SportZone>
          <!-- 카트 -->
          <div v-if="cartItem.length > 0" id="open-cart" class="draggable" style="left: 312px; top: 675.2px;">
            <div class=""></div>
            <span>베팅카트</span>
            <span class="odds">{{ odds }}</span><b> {{  cartItem.length }}</b>
          </div>
          <div id="tab-sport" class="tab-content-box sport-box sport-live">
            <CenterList :origin-list="lists" :add-cart="addCart" :check-cart="checkCart" :select-game="selectGame" :selectedGame="selectedGame"></CenterList>
            <div></div>
          </div>
          <transition name="fade">
            <Cart v-if="openCart" :toggle-cart="toggleCart"></Cart>
          </transition>
          <transition name="fade">
            <CenterDetail v-if="selectedGame" :select-game="selectGame" :game="selectedGame" :add-cart="addCart" :check-cart="checkCart"></CenterDetail>
          </transition>
        </div>
      </div>
    </section>
</div>
</template>

<script>
// 데스크탑
import SportZone from '@/components/Game/Sports/Inplay/SportZone'
import CenterList from '@/components/Game/Sports/Inplay/CenterList'
import CenterDetail from '@/components/Game/Sports/Inplay/CenterDetail'
import Cart from '@/components/Cart/InplayCart'
// import NoUserCart from '@/components/Cart/NoUserInplayCart'

// import List from '@/components/Game/Sports/Inplay/InplayList2'
// import NewCart from '@/components/Betting/InplayCart'

// 모바일
// import MCart from '@/components/Betting/MInplayCart'
// import MList from '@/components/Game/Sports/Inplay/MInplayList'

//공통
// import Loading from '@/components/Loading'
import MSG from '@/contants/msg'
import {mapState} from "vuex";
import moment from "moment";

export default {
  name: "Inplay",
  components: {
    SportZone,
    CenterList,
    CenterDetail,
    Cart,
    // NoUserCart
    // List,
    // NewCart,
    // MCart,
    // MList,
    // Loading
    // RingLoader,
  },
  data: function () {
    return {
      type: 'korean',
      category: null,
      isLoading: false,
      selectedGame: null,
      search: false,
      keyword: null,
      sticky: false,
      lists: null,
      isScrollDown: false,
      payload: {},
      initTime: null,
      openCart: false,
    }
  },
  computed: {
    ...mapState({
      originList: state => state.inplay.originList,
      waitList: state => state.inplay.waitList,
      user: state => state.user.user,
      cartItem: state => state.inplay.cart,
    }),
    odds: function(){
      return Number(this.$store.getters["INPLAY_TOTAL_ODDS"])
    },
    /* 해당경기의 베팅을 방지하는 설정*/
    blockSetting: function () {
      return this.$store.getters['INPLAY_SETTING'];
    },
    blockTypeSetting: function () {
      return this.$store.getters['INPLAY_BLOCK_TYPE_SETTING'];
    },
  },
  watch: {
    selectedGame(val) {
      if (!val) {
        this.lists = null;
        this.initFolder();
      }
    },
  },
  mounted: function () {
    setTimeout(() => {
      if (!this.$socket.connected) {
        this.$store.dispatch('SHOW_ERR_MSG', MSG.error.socketNotConnected);
        this.$socket.client.connect();
        this.initFolder()
      }
    }, 2000)
    window.addEventListener('scroll', this.handleScroll)
    if (this.user) {
      const config = this.user.members_detail_setting;
      if (!config.베팅권한.인플레이.active) {
        this.$store.dispatch('SHOW_ERR_MSG', MSG.error.authorization);
        this.$router.push({name: '메인'});
      }
    }
    this.initFolder()
    // this.reqWait();
    this.interval = setInterval(this.initFolder2, 20000);
    this.interval2 = setInterval(this.checkInitTime, 1000);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    clearInterval(this.interval);
    clearInterval(this.interval2);
  },
  methods: {
    checkInitTime() {
      const now = moment().unix();
      if (this.initTime && now - this.initTime > 180) {
        this.$store.dispatch('SHOW_ERR_MSG', '화면 로딩후 일정시간이 지나 인플레이 데이터의 갱신이 필요합니다.');
        this.initFolder();
        this.$store.dispatch('ALL_INPLAY_CLEAR_ITEM');
      }
    },
    handleScroll: function () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition > 200) {
        this.isScrollDown = true;
      } else {
        this.isScrollDown = false;
      }
    },
    // 경기요청
    initFolder(payload) {
      this.isLoading = false;
      this.$store.dispatch('GET_INPLAY_LIST', {payload}).then((result) => {
        if (result.data.success) {
          this.isLoading = true;
          this.lists = this.originList;
          this.initTime = moment().unix();
        } else {
          console.log('인플레이 경기요청 에러', result)
          this.$store.dispatch('SHOW_ERR_MSG3', result.data.msg);
        }
      })
    },
    // 경기요청
    initFolder2(payload) {
      this.$store.dispatch('GET_INPLAY_LIST', {payload}).then((result) => {
        if (result.data.success) {
          this.lists = this.originList;
        } else {
          console.log('인플레이 경기요청2 에러')
          this.$store.dispatch('SHOW_ERR_MSG3', result.data.msg);
        }
      })
    },
    reqWait() {
      this.$store.dispatch('GET_INPLAY_WAIT_LIST', {payload: {}})
    },
    // 게임종목
    changeCategory: function (category) {
      this.category = category;
      if (category === '') {
        return this.lists = this.originList;
      }
      this.lists = this.originList.filter(e => e.sportSeq === category);
    },
    // 베팅카트에 담기
    addCart: function (bets, folders, game) {
      if (this.user) {
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.인플레이.active) return alert(MSG.error.authorization);
      } else return alert(MSG.error.isNotLogin);

      const cartItem = {
        gameId: game.id,
        gameSeq: game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: game.sportId,
        foldersId: folders.id,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueNameKr: game.league.nameKr,
        leagueNameEn: game.league.nameEn,
        leagueNameIcon: game.league.icon,
        sportNameKr: game.sport.nameKr,
        sportNameEn: game.sport.nameEn,
        sportNameIcon: game.sport.icon,
        homeNameKr: game.homeTeam.nameKr,
        homeNameEn: game.homeTeam.nameEn,
        awayNameKr: game.awayTeam.nameKr,
        awayNameEn: game.awayTeam.nameEn,
        marketsId: folders.marketId,
        isCheck: folders.market.isCheck,
        marketNameKr: folders.periodSequence !== '0' ? folders.market.nameKr?.replace('{sw}', folders.periodSequence) : folders.market.nameKr,
        marketNameEn: folders.periodSequence !== '0' ? folders.market.nameEn?.replace('{sw}', folders.periodSequence) : folders.market.nameEn,
        startTime: game.startTime,
        odds1: null,
        odds2: null,
        odds3: null,
        betType: '인플레이',
        detailType: null,
        currentPeriod: game.currentPeriod,
        currentTime: `${game.subResult}`,
        homeScore: game.homeResult,
        awayScore: game.awayResult,
        penalty: null,
        maxAmount: game.league.maxAmount,
      }

      if (cartItem.marketNameKr?.indexOf('{sw}') >= 0) {
        if (folders.periodSequence !== '0') {
          cartItem.marketNameKr = cartItem.marketNameKr.replace('{sw}', folders.periodSequence)
          cartItem.marketNameEn = cartItem.marketNameEn.replace('{sw}', folders.periodSequence)
        }
      }

      if (cartItem.marketNameKr?.indexOf('1팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('1팀', cartItem.homeNameKr || cartItem.homeNameEn)
      }

      if (cartItem.marketNameKr?.indexOf('2팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('2팀', cartItem.awayNameKr || cartItem.awayNameEn)
      }
      this.$store.dispatch('ADD_CART_INPLAY_ITEM', cartItem)
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets) {
      let index = this.cartItem.find(item => item.betsSeq === bets.seq)
      return !!index;
    },

    moveDetail: function (game) {
      this.$router.push({path: '/sport/live/d', query: {gid: game.id}})
    },
    uniq: function (array) {
      return Array.from(new Set(array));
    },
    moveBack: function () {
      this.selectedGame = null;
    },
    filterByGames: function (sortType, target) {
      this.selectedGame = null;
      if (sortType === 'all') {
        return this.lists = this.originList;
      }
      if (sortType === 'league') {
        this.lists = this.originList.filter(e => String(e.leagueId) === String(target));
      }

      if (sortType === 'date') {
        this.lists = this.originList.filter(e => e.startTime.indexOf(target) >= 0);
      }

      if (sortType === 'sport') {
        this.lists = this.originList.filter(e => String(e.sportId) === String(target));
      }

      if (sortType === 'team') {
        this.lists = this.originList.filter(e =>
            e.homeTeam.nameEn.indexOf(target) >= 0 ||
            e.homeTeam.nameKr.indexOf(target) >= 0 ||
            e.awayTeam.nameEn.indexOf(target) >= 0 ||
            e.awayTeam.nameKr.indexOf(target) >= 0
        );
      }
    },
    toggleCart: function () {
      this.openCart = !this.openCart
    },
    /* 서버 설정에 따라서 경기 베팅을 막는다.
    *  True
    * */
    checkBlockGame: function (game) {
      let blockConfig = {};
      if (game.sportId === 1) {
        blockConfig = this.blockSetting['football'];
        if (game.subResult?.indexOf('HT') >= 0) return false;
        if (Number(game.currentPeriod) === 1 && 45 - Number(game.currentTime) <= Number(blockConfig.first)) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && 90 - Number(game.currentTime) <= Number(blockConfig.second)) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 2) {
        blockConfig = this.blockSetting['hockey'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length - 1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 3) {
        blockConfig = this.blockSetting['basketball'];
        let remainTime = game.remainTime;
        let currentPeriod = 1;
        const time = game.subResult?.split(' ');
        if (time) {
          remainTime = `00:${time[time.length - 1]}`;
          currentPeriod = time.reduce((cnt, element) => cnt + (element.includes('(')), 0);
          console.log(currentPeriod);
        }
        // console.log(remainTime)
        console.log(`남은시간: ${remainTime}`, `경기제한: ${game.isSuspended} 경기노출: ${game.isVisible}`, `남은경기시간없음: ${remainTime === '00:00:00'}`);
        if (remainTime === '00:00:00') {
          if (game.isSuspended === 'y' || game.isVisible === 'n') return true;
          if (currentPeriod >= 3) return;
        } else {
          if (currentPeriod === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod > 4) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }
      }
      if (game.sportId === 5) {
        blockConfig = this.blockSetting['volleyball'];
        const name = `set${game.currentPeriod}Score`;
        const setHomeScore = game[name] ? game[name].split(':')[0] : 0;
        const setAwayScore = game[name] ? game[name].split(':')[1] : 0;
        if (Number(game.currentPeriod) === 1) {
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;
          if (Number(setHomeScore) >= Number(blockConfig.first) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.first) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 2) {
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.second) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.second) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 3) {
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.third) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.third) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 4) {
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.four) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.four) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 5) {
          if (Number(setHomeScore) >= Number(blockConfig.five) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.five) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

      }
      if (game.sportId === 6) {
        blockConfig = this.blockSetting['americanFootball'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length - 1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 11) {
        blockConfig = this.blockSetting['baseball'];
        const detailResult = game.subResult?.split(' ');
        const periodCnt = detailResult?.filter(e => e.indexOf('(') >= 0).length;
        if (periodCnt > Number(blockConfig.first)) return true;
      }

      return false;
    },
    selectGame(game){
      this.selectedGame = game;
    }
  },
}
</script>

