<template>
  <div class="sport-content">
    <ul class="result"><!--v-if-->
        <li v-for="folder in originList"><b>{{ folder | resultMarketName }}</b><b>{{ folder.bets.find((bet) => bet.settlement === 'win')?.name | betsName }}</b></li>
    </ul>
</div>
  </template>

<script>
export default {
  name: "Price",
  props: ['game'],
  components: {
  },
  data: function () {
    return {
      originList: [],
      openDetailBet: [],
      selectedMarket: null,
      isLoading: false,
      closed: [],
    }
  },
  mounted() {
    this.initFolder();
  },
  methods: {
    initFolder: function () {
      const payload = {
        gameId: this.game.id
      }
      return this.$store.dispatch('GET_RESULT_DETAIL', { payload })
          .then(data => {
            const result = data.data;
            this.originList = result.payload.list
            this.isLoading = true;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
  }
}
</script>

<style>
.swapping-squares-spinner, .swapping-squares-spinner * {
  box-sizing: border-box;
  left: 50%;
}

.swapping-squares-spinner {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.swapping-squares-spinner .square {
  height: calc(65px * 0.25 / 1.3);
  width:  calc(65px * 0.25 / 1.3);
  animation-duration: 1000ms;
  border: calc(65px * 0.04 / 1.3) solid var(--spbgColor02);
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  animation-iteration-count: infinite;
}

.swapping-squares-spinner .square:nth-child(1) {
  animation-name: swapping-squares-animation-child-1;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(2) {
  animation-name: swapping-squares-animation-child-2;
  animation-delay: 0ms;
}

.swapping-squares-spinner .square:nth-child(3) {
  animation-name: swapping-squares-animation-child-3;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(4) {
  animation-name: swapping-squares-animation-child-4;
  animation-delay: 0ms;
}

@keyframes swapping-squares-animation-child-1 {
  50% {
    transform: translate(150%,150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-2 {
  50% {
    transform: translate(-150%,150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-3 {
  50% {
    transform: translate(-150%,-150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-4 {
  50% {
    transform: translate(150%,-150%) scale(2,2);
  }
}
</style>
