<template>
<div class="overlay">
    <div class="overlay__inner">
        <div class="overlay__content">
          <div class="swapping-squares-spinner" :style="spinnerStyle">
            <div class="square"></div>
            <div class="square"></div>
            <div class="square"></div>
            <div class="square"></div>
          </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: "Overray",
}
</script>

<style scoped>
.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    position: fixed;
    background: #222;
    opacity: 0.6;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 80% 0 0 42%;
}

.overlay__content {
    /* left: 87%; */
    position: absolute;
    /* top: 40%; */
    /* transform: translate(-50%, -50%); */
}
.swapping-squares-spinner, .swapping-squares-spinner * {
      box-sizing: border-box;
    }

.swapping-squares-spinner {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.swapping-squares-spinner .square {
  height: calc(65px * 0.25 / 1.3);
  width:  calc(65px * 0.25 / 1.3);
  animation-duration: 1000ms;
  border: calc(65px * 0.04 / 1.3) solid var(--spbgColor02);
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  animation-iteration-count: infinite;
}

.swapping-squares-spinner .square:nth-child(1) {
  animation-name: swapping-squares-animation-child-1;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(2) {
  animation-name: swapping-squares-animation-child-2;
  animation-delay: 0ms;
}

.swapping-squares-spinner .square:nth-child(3) {
  animation-name: swapping-squares-animation-child-3;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(4) {
  animation-name: swapping-squares-animation-child-4;
  animation-delay: 0ms;
}

@keyframes swapping-squares-animation-child-1 {
  50% {
    transform: translate(150%,150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-2 {
  50% {
    transform: translate(-150%,150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-3 {
  50% {
    transform: translate(-150%,-150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-4 {
  50% {
    transform: translate(150%,-150%) scale(2,2);
  }
}
</style>
