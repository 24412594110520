<template>
  <section id="content" class="sporttotalheight">
      <ul id="tabs-sport" class="tab-type sport-result">
          <li class="active"><a>
              <div class="tab-logo sp-tab-soccer"></div>
              <div class="tab-name">축구</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-icehockey"></div>
              <div class="tab-name">아이스 하키</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-basketball"></div>
              <div class="tab-name">농구</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-tennis"></div>
              <div class="tab-name">테니스</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-volleyball"></div>
              <div class="tab-name">배구</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-americanfootball"></div>
              <div class="tab-name">미식 축구</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-australianfootball"></div>
              <div class="tab-name">호주식 축구</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-badminton"></div>
              <div class="tab-name">배드민턴</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-baseball"></div>
              <div class="tab-name">야구</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-beachvolleyball"></div>
              <div class="tab-name">비치 발리볼</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-bowls"></div>
              <div class="tab-name">론볼</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-boxing"></div>
              <div class="tab-name">복싱</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-chess"></div>
              <div class="tab-name">체스</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-cricket"></div>
              <div class="tab-name">크리켓</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">사이클</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-darts"></div>
              <div class="tab-name">다트</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-floorball"></div>
              <div class="tab-name">플로어볼</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-formula1"></div>
              <div class="tab-name">포뮬러 1</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-futsal"></div>
              <div class="tab-name">풋살</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-golf"></div>
              <div class="tab-name">골프</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-handball"></div>
              <div class="tab-name">핸드볼</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-hockey"></div>
              <div class="tab-name">필드 하키</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">경마</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-pool"></div>
              <div class="tab-name">풀</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-rallyugbyleague"></div>
              <div class="tab-name">럭비 리그</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-rugbyunion"></div>
              <div class="tab-name">럭비</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-snooker"></div>
              <div class="tab-name">스누커</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-tabletennis"></div>
              <div class="tab-name">탁구</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-waterpolo"></div>
              <div class="tab-name">워터 폴로</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">MMA 이종격투기</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">경마 (버추얼)</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">개경주 (버추얼)</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-gaelicfootball"></div>
              <div class="tab-name">게일식 축구</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-hurling"></div>
              <div class="tab-name">헐링</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">e - 농구</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-callofduty"></div>
              <div class="tab-name">콜 오브 듀티</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-counterstrikego"></div>
              <div class="tab-name">카운터 스트라이크 : GO</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-dota2"></div>
              <div class="tab-name">도타 2</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-leagueoflegends"></div>
              <div class="tab-name">리그 오브 레전드</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-heroesofthestorm"></div>
              <div class="tab-name">히어로즈 오브 더 스톰</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-overwatch"></div>
              <div class="tab-name">오버워치</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-starcraft2"></div>
              <div class="tab-name">스타크래프트 2</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-speedway"></div>
              <div class="tab-name">스피드 웨이</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-squash"></div>
              <div class="tab-name">스쿼시</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-specialbets"></div>
              <div class="tab-name">스페셜 베팅</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-netball"></div>
              <div class="tab-name">네트볼</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-lacross"></div>
              <div class="tab-name">라크로스</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">마블레이싱 (버추얼)</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-archeryh2h"></div>
              <div class="tab-name">양궁</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">경륜 (버추얼)</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-kingofglory"></div>
              <div class="tab-name">킹 오브 글로리</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">카바디</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">축구 Pro (버추얼)</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">페널티킥 (버추얼)</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">그레하운즈</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">축구 리그 (버추얼)</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">볼 하키</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-basketballshots"></div>
              <div class="tab-name">농구 샷</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-valorant"></div>
              <div class="tab-name">발로란트</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-undefined"></div>
              <div class="tab-name">드래그 레이싱 (버추얼)</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-archery"></div>
              <div class="tab-name">양궁 슈팅</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-teqball"></div>
              <div class="tab-name">테크볼</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-pistolshooting"></div>
              <div class="tab-name">권총 사격</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-leagueoflegendswildrift"></div>
              <div class="tab-name">리그 오브 레전드: 와일드 리프트</div>
          </a></li>
          <li class=""><a>
              <div class="tab-logo sp-tab-ageofempires"></div>
              <div class="tab-name">에이지 오브 엠파이어</div>
          </a></li>
      </ul>
      <div class="tab-content-box sport-box sport-result">
          <div class="tab-content">
              <div class="head-league top-result-filter"><span><input type="text" disabled=""></span><span
                      class="icon-filter"></span></div>
              <div id="resultFilter" class="result-filter">
                  <div class="list"><b>날짜:</b>
                      <div class="input-group">
                          <div class="input-date">
                              <div class="mx-datepicker">
                                  <div class="mx-input-wrapper"><input name="date" type="text" autocomplete="off"
                                                                      class="mx-input"><i class="mx-icon-clear">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em"
                                          height="1em">
                                          <path d="M810.005 274.005 572.011 512l237.994 237.995-60.01 60.01L512 572.011 274.005 810.005l-60.01-60.01L451.989 512 213.995 274.005l60.01-60.01L512 451.989l237.995-237.994z"></path>
                                      </svg>
                                  </i><i class="mx-icon-calendar">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em"
                                          height="1em">
                                          <path d="M940.218 107.055H730.764v-60.51H665.6v60.51H363.055v-60.51H297.89v60.51H83.78c-18.617 0-32.581 13.963-32.581 32.581v805.237c0 18.618 13.964 32.582 32.582 32.582h861.09c18.619 0 32.583-13.964 32.583-32.582V139.636c-4.655-18.618-18.619-32.581-37.237-32.581zm-642.327 65.163v60.51h65.164v-60.51h307.2v60.51h65.163v-60.51h176.873v204.8H116.364v-204.8H297.89zM116.364 912.291V442.18H912.29v470.11H116.364z"></path>
                                      </svg>
                                  </i></div><!--teleport start--><!--teleport end--></div>
                          </div>
                          <div class="input-date">
                              <div class="mx-datepicker">
                                  <div class="mx-input-wrapper"><input name="date" type="text" autocomplete="off"
                                                                      class="mx-input"><i class="mx-icon-clear">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em"
                                          height="1em">
                                          <path d="M810.005 274.005 572.011 512l237.994 237.995-60.01 60.01L512 572.011 274.005 810.005l-60.01-60.01L451.989 512 213.995 274.005l60.01-60.01L512 451.989l237.995-237.994z"></path>
                                      </svg>
                                  </i><i class="mx-icon-calendar">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em"
                                          height="1em">
                                          <path d="M940.218 107.055H730.764v-60.51H665.6v60.51H363.055v-60.51H297.89v60.51H83.78c-18.617 0-32.581 13.963-32.581 32.581v805.237c0 18.618 13.964 32.582 32.582 32.582h861.09c18.619 0 32.583-13.964 32.583-32.582V139.636c-4.655-18.618-18.619-32.581-37.237-32.581zm-642.327 65.163v60.51h65.164v-60.51h307.2v60.51h65.163v-60.51h176.873v204.8H116.364v-204.8H297.89zM116.364 912.291V442.18H912.29v470.11H116.364z"></path>
                                      </svg>
                                  </i></div><!--teleport start--><!--teleport end--></div>
                          </div>
                      </div>
                  </div>
                  <div class="list"><b>팀명:</b>
                      <div class="input-group">
                          <div class="input-search"><span class="icon-search"></span><input type="text" placeholder="검색">
                          </div>
                      </div>
                  </div>
                  <div class="list"><b>리그:</b>
                      <div class="select-group"><select number="">
                          <option value="0">전체</option>
                          <option value="18274117">SRL 매치 - Eredivisie SRL</option>
                          <option value="18273804">SRL 매치 - Ligue 1 SRL</option>
                          <option value="18273803">SRL 매치 - 세리에 A SRL</option>
                          <option value="18273822">SRL 매치 - 슈퍼 리그 SRL</option>
                          <option value="12224">남아프리카 공화국 - Varsity Football Challenge</option>
                          <option value="2984">남아프리카 공화국 - 내셔널 퍼스트 디비전</option>
                          <option value="33962">노르웨이 - U19 챔피언십</option>
                          <option value="10926">노르웨이 - 디비전 3</option>
                          <option value="10486">니카라과 - 프리메라 디비시온</option>
                          <option value="4674">덴마크 - 엘리테디비시오넨 - 여자</option>
                          <option value="1878">독일 - 레기오날리가 노스이스트</option>
                          <option value="11183">러시아 - 4. 디비전</option>
                          <option value="18286320">러시아 - FNL 세컨드 리그 디비전 B</option>
                          <option value="2002">러시아 - M-Liga</option>
                          <option value="11172">러시아 - 디비전 3</option>
                          <option value="13061">말라위 - TNM 슈퍼 리그</option>
                          <option value="11251">멕시코 - 리가 MX – 여자</option>
                          <option value="9251">몰도바 - 리가 1</option>
                          <option value="3115">몰타 - 프리미어 리그</option>
                          <option value="18289401">미국 - USL Cup</option>
                          <option value="3093">벨라루스 - 퍼스트 리그</option>
                          <option value="11159">벨라루스 - 프리미어 리그 - 여자</option>
                          <option value="20035">부룬디 - 프리미어 리그</option>
                          <option value="12941">북 아메리카 - NCAA - 여자</option>
                          <option value="1805">불가리아 - 퍼스트 리그</option>
                          <option value="12944">브라질 - Campeonato Brasileiro A1 - Women</option>
                          <option value="18290539">브라질 - Copa ClaroBet</option>
                          <option value="18600">브라질 - Copa Gaucho</option>
                          <option value="18265944">브라질 - U19 Campeonato Maranhense</option>
                          <option value="18276312">브라질 - U20 캄페오나투 브라질레이루</option>
                          <option value="11556">브라질 - U20 캄피우나투 파울리스타</option>
                          <option value="18267625">브라질 - 캄페오나투 파라이바누 2. 디비전</option>
                          <option value="1799">브라질 - 코파 델 브라질</option>
                          <option value="12202">브라질 - 코파 히우</option>
                          <option value="16045">브루나이 - 슈퍼리그</option>
                          <option value="2960">사우디 아라비아 - 1st 디비전</option>
                          <option value="2971">사우디 아라비아 - 사우디 프로페셔널리그</option>
                          <option value="18286820">세르비아 - Amateur League</option>
                          <option value="11803">세르비아 - 세르비안 리그</option>
                          <option value="11174">세르비아 - 슈퍼 리가 - 여자</option>
                          <option value="3004">스웨덴 - 스벤 스카 컵</option>
                          <option value="36940">스페인 - La Liga - Cards and Corners</option>
                          <option value="16926">스페인 - La Liga. Special Bets</option>
                          <option value="545">스페인 - 라리가</option>
                          <option value="12827">슬로베니아 - Cup - 여자</option>
                          <option value="2982">슬로베니아 - 컵</option>
                          <option value="3152">싱가포르 - 프리미어 리그</option>
                          <option value="10733">아랍에미리트 - U21 리그</option>
                          <option value="18290422">아랍에미리트 - U23 Pro League</option>
                          <option value="3022">아랍에미리트 - UAE 프로 리그</option>
                          <option value="1687">아르메니아 - 아르메니아 프리미어리그</option>
                          <option value="18286216">아르헨티나 - U20 Primera B</option>
                          <option value="18281810">아르헨티나 - 리저브 리그</option>
                          <option value="14097">아르헨티나 - 리저브 리그 컵</option>
                          <option value="18286712">아메리카 - CONCACAF Central American Cup</option>
                          <option value="15875">아메리카 - Caribbean Club Cup</option>
                          <option value="1897">아이슬란드 - 1.데일드</option>
                          <option value="18282592">아이슬란드 - 2. Deild - Women</option>
                          <option value="11548">아이슬란드 - 4. 데일드</option>
                          <option value="5015">아이슬란드 - U19 리그</option>
                          <option value="11532">아일랜드 - 린스터 시니어 리그</option>
                          <option value="15668">아제르바이잔 - 프리미어 리그</option>
                          <option value="11200">에스토니아 - Meistriliiga - 여자</option>
                          <option value="10714">에콰도르 - 세리에 B</option>
                          <option value="37535">에콰도르 - 컵</option>
                          <option value="14972">엘살바도르 - 리저브 리그</option>
                          <option value="18330">요르단 - Shield Cup</option>
                          <option value="3023">우루과이 - 프리메라 디비시온</option>
                          <option value="11239">우즈베키스탄 - 우즈베키스탄 컵</option>
                          <option value="4697">우크라이나 - U19 Championship</option>
                          <option value="3020">우크라이나 - VBET 프리미어리그</option>
                          <option value="3036">월드 - U19 국제친선 - 여자</option>
                          <option value="573">월드 - 클럽 친선 경기</option>
                          <option value="10579">월드 - 클럽친선 - 여자 (possible format change)</option>
                          <option value="18275688">유럽 - UEFA 유로파 리그 자격</option>
                          <option value="18286954">유럽 - UEFA Europa Conference League Qualification - Cards and Corners
                          </option>
                          <option value="18286521">유럽 - UEFA 유로파 컨퍼런스 리그 자격</option>
                          <option value="17080">유럽 - 스페셜 베팅</option>
                          <option value="1905">이란 - 프로 리그</option>
                          <option value="18276836">이스라엘 - Premier League Cup - Women</option>
                          <option value="9312">이스라엘 - U19 청소년 리그</option>
                          <option value="1911">이스라엘 - 리가 레우밋</option>
                          <option value="1909">이스라엘 - 이스라엘 컵</option>
                          <option value="28802">인도 - Mumbai Super Division</option>
                          <option value="18986">인도 - 미조람 프리미어 리그</option>
                          <option value="4935">인도 - 캘커타 프리미어 부문</option>
                          <option value="10834">일본 - J3 리그</option>
                          <option value="18284016">잉글랜드 - FA National League Cup - Women</option>
                          <option value="18633">잉글랜드 - 프로페셔널 디벨롭먼트 리그</option>
                          <option value="18547">잉글랜드 - 프리미어 리그 2</option>
                          <option value="9227">체코 - 1.Liga - 여자</option>
                          <option value="4717">체코 - U19 리그</option>
                          <option value="1828">체코 - 체코컵</option>
                          <option value="18280286">칠레 - U21 챔피언십</option>
                          <option value="1809">칠레 - 프리메라 디비시온</option>
                          <option value="4690">카자흐스탄 - 퍼스트 디비전</option>
                          <option value="25894">코소보 - 퍼스트 리그</option>
                          <option value="11083">코스타리카 - 세군다 디비시온</option>
                          <option value="1818">콜롬비아 - 코파 콜롬비아</option>
                          <option value="14113">쿠웨이트 - 디비전 1</option>
                          <option value="11193">탄자니아 - 프리미어 리그</option>
                          <option value="18904">태국 - 대학 챔피언십</option>
                          <option value="10487">태국 - 태국 리그 2</option>
                          <option value="10364">터키 - U19 리그</option>
                          <option value="18270003">파나마 - LFF - Women</option>
                          <option value="10445">파나마 - Liga Prom</option>
                          <option value="9255">파라과이 - 디비전 인터 미디어</option>
                          <option value="10500">파라과이 - 리저브 리그</option>
                          <option value="1973">파라과이 - 프리메라 디비시온</option>
                          <option value="34374">포르투갈 - U23 리가 헤벨라상</option>
                          <option value="22220">폴란드 - III 리가 - 그룹 II</option>
                          <option value="32165">폴란드 - 리지널 리그</option>
                          <option value="9284">폴란드 - 중앙 주니어 리그</option>
                          <option value="11136">핀란드 - 콜맨</option>
                          <option value="12658">헝가리 - U19 리그</option>
                          <option value="4667">호주 - NPL Victoria - 여자</option>
                          <option value="1706">호주 - NPL 빅토리아</option>
                          <option value="4750">호주 - NPL 사우스 호주</option>
                          <option value="11743">호주 - NPL 웨스턴 호주 - 여자</option>
                          <option value="5045">호주 - NPL 태즈 메이 니아</option>
                          <option value="22874">호주 - Tasmania Southern Championship 1</option>
                          <option value="18285188">호주 - U21 NPL Tasmania</option>
                          <option value="18281798">호주 - U21 NPL Western Australia - Women</option>
                          <option value="18277397">호주 - U23 NPL 퀸즈랜드</option>
                          <option value="15730">호주 - 노던 테리토리 프리미어 리그</option>
                          <option value="18274724">호주 - 노던 테리토리 프리미어 리그 (리저브)</option>
                          <option value="11281">호주 - 빅토리아 주립 리그 1</option>
                          <option value="18275171">호주 - 웨스트 스테이트 리그 1 - 여자</option>
                          <option value="18281543">호주 - 퀸즐랜드 프리미어 리그 3</option>
                          <option value="16296">호주 - 태즈메이니아 남부 챔피언십</option>
                          <option value="1888">홍콩 - 프리미어 리그</option>
                      </select></div>
                  </div>
                  <div class="list list-switch">
                    <b>스포츠실시간:</b>
                    <label class="toggle">
                      <input class="toggle-checkbox" type="checkbox" true-value="1" alse-value="0">
                      <div class="toggle-switch"></div>
                  </label></div>
                  <button class="submit close-filter">검색</button>
              </div>
              <div class="sport-result-box">
                  <div class="sport-result-panel"><!--v-if--><!--v-if-->
                      <div class="sport-items">
                          <ul class="sport-group">
                              <li class="content-visible" v-for="game in lists">
                                <a class="sport-trigger" @click="toggleDetail(game.id)">
                                  <ul>
                                      <li><b>{{ game.homeTeam | teamName }}</b><b>{{ game.homeResult }} </b></li>
                                      <li><b>{{ game.awayTeam | teamName }}</b><b>{{ game.awayResult }} </b></li>
                                      <li>
                                        <p>{{ game.location | locationName }} - {{ game.league | leagueName }}</p>
                                        <p style="text-align: right;">{{ game.startTime | formatDate('YY/MM/DD HH:mm:ss')}}</p>
                                      </li>
                                  </ul>
                                </a>
                                <Detail :game="game" v-if="checkArray(game.id)"></Detail>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
// 데스크탑
import Detail from '@/components/Result/Detail'

// import MSG from '../../../../contants/msg'
// import {mapState} from "vuex";
export default {
  name: "GameResult",
  components: {
    Detail
  },
  data: function () {
    return {
      lists: [],
      payload: {
        page: 1,
        size: 20,
        type: 'sports',
        sport: null,
        date: null,
      },
      pagination: {},
      type: '',
      openDetailResults: [],
    }
  },
  mounted: function () {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function (payload) {
      return this.$store.dispatch('GET_RESULT', { payload })
          .then(data => {
            const result = data.data.payload;
            this.lists = result.list;
            this.pagination = result.pagination;
            this.loading.isLoading = false;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    toggleDetail: function (id) {
      this.toggle(this.openDetailResults, id)
    },
    removeAtIndex: function (arr, index) {
      this.openDetailResults.splice(index, 1);
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailResults = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    checkArray: function (id) {
      let result = this.openDetailResults.indexOf(id) >= 0
      return result
    },
  },
}
</script>

<style scoped>
.sport-result-box .sport-trigger ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .813rem;
}
.sport-result-box .sport-trigger ul li p {
    padding-left: 0vw;
    font-weight: 400;
    font-size: .688rem;
}
.sport-result-box .sport-trigger ul li p:last-child {
    color: var(--sptxtColor02);
    font-weight: 700;
}
.swapping-squares-spinner, .swapping-squares-spinner * {
  box-sizing: border-box;
  left: 50%;
  top: 50%;
}

.swapping-squares-spinner {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.swapping-squares-spinner .square {
  height: calc(65px * 0.25 / 1.3);
  width:  calc(65px * 0.25 / 1.3);
  animation-duration: 1000ms;
  border: calc(65px * 0.04 / 1.3) solid var(--spbgColor02);
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  animation-iteration-count: infinite;
}

.swapping-squares-spinner .square:nth-child(1) {
  animation-name: swapping-squares-animation-child-1;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(2) {
  animation-name: swapping-squares-animation-child-2;
  animation-delay: 0ms;
}

.swapping-squares-spinner .square:nth-child(3) {
  animation-name: swapping-squares-animation-child-3;
  animation-delay: 500ms;
}

.swapping-squares-spinner .square:nth-child(4) {
  animation-name: swapping-squares-animation-child-4;
  animation-delay: 0ms;
}

@keyframes swapping-squares-animation-child-1 {
  50% {
    transform: translate(150%,150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-2 {
  50% {
    transform: translate(-150%,150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-3 {
  50% {
    transform: translate(-150%,-150%) scale(2,2);
  }
}

@keyframes swapping-squares-animation-child-4 {
  50% {
    transform: translate(150%,-150%) scale(2,2);
  }
}
</style>
