<template>
  <!-- <td class="soprts_in_acc_2 "
      :style="[width === 2 ? {'width': '50%'} : {'width': '33%'}]"
      :class="{'sel': checkCart(bets)}"
      @click="addCart(bets, folder, game)"
  >
    <div class="sports_v_l_1">
      {{ teamName }}
    </div>
    <div class="sports_v_r_1">
      <i v-if="priceStatus === 'up'" class="ico-up blink"></i>
      <i v-if="priceStatus === 'down'" class="ico-down blink"></i>
      <span class="font_002 ">
        {{ bets.price }}
      </span>
      <i v-if="priceStatus" class="sel_old ">({{ startPrice }})</i>
    </div>
  </td> -->
  <li class="l-team" v-if="isLock" :class="{'on': checkCart(bets), 'w50': folder.bets.length === 2}" @click="addCart(bets, folder, game)">
    <div class="team-name">{{ teamName }}</div>
    <div class="odds-panel">
        <div class="arrow-type-box"></div>
        <div class="odd" :class="{'up':priceStatus === 'up', 'down': priceStatus === 'down' }"><span class=""></span>{{ bets.price }}</div>
    </div>
    
    <div v-if="priceStatus === 'up'" class="icon-arrow-odd up fade"
          style="display: block;"></div>
    <div v-if="priceStatus === 'down'" class="icon-arrow-odd down fade"
          style="display: block;"></div>
  </li>
  <li v-else class="l-team" :class="{'on': checkCart(bets)}">
    <div class="team-name">{{ teamName }}</div>
    <div class="odds-panel">
        <div class="arrow-type-box"></div>
        <div class="odd"><span class=""></span>{{ bets.price }}</div>
    </div>
  </li>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game'],
  data: function(){
    return {
      startPrice: null,
      priceStatus: null,
    }
  },
  computed: {
    price: function(){
      return Number(this.bets.price).toFixed(2)
    },
    teamName: function(){
      let name = '';
      if (this.bets.name === 'W1') return `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn}` ;
      if (this.bets.name === 'W2') return `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn}` ;
      if (this.bets.name === 'Home') return `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} (${this.bets.line})` ;
      if (this.bets.name === 'Away') return `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} (${this.bets.line})` ;
      if (this.bets.name === 'Over') return `오버 ${this.bets.line}` ;
      if (this.bets.name === 'Under') return `언더 ${this.bets.line}`;
      if (this.bets.name === 'X') name = '무';
      if (this.bets.name === '1X') name = '1팀승 또는 무승부';
      if (this.bets.name === '12') name = '1팀승 또는 2팀승';
      if (this.bets.name === 'X2') name = '무승부 또는 2팀승';
      if (this.bets.name === 'Odd') name = '홀';
      if (this.bets.name === 'Even') name = '짝';
      if (this.bets.name === 'Yes') name = '예';
      if (this.bets.name === 'No') name = '아니오';
      if (this.bets.name === 'NoGoal') name = '노골';
      if (this.folder.market.isHandicap === 'y') name += ` (${this.bets.line})`
      return name;
    },
    isLock(){
      if (this.blockSetting === '1') {
        if (this.checkBlockGame()) return false;
      }
      if (this.blockSetting === '2') {
        if (this.checkBlockGame()) {
          if (this.folder.market.isCheck === 'n') return false; 
        }
      }
      if (this.game.isSuspended === 'y') return false;
      if (this.folder.isVisible === 'n' || this.folder.isSuspended === 'y') return false;
      return true
    },
    /* 해당경기의 베팅을 방지하는 설정*/
    blockSetting: function(){
      return this.$store.getters['INPLAY_SETTING'];
    },
    blockTypeSetting: function(){
      return this.$store.getters['INPLAY_BLOCK_TYPE_SETTING'];
    },
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.priceStatus = 'down';
      if (Number(newVal) - Number(val) > 0) this.priceStatus = 'up';
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    priceStatus: function(){
      setTimeout(() => {
        this.priceStatus = null
        this.startPrice = null;
      } ,5000)
    },
  },
  methods: {
    checkBlockGame: function(){
      let blockConfig = {};
      if (this.game.sportId === 1) {
        blockConfig = this.blockSetting['football'];
        if (this.game.subResult?.indexOf('HT') >= 0) return false;
        if (Number(this.game.currentPeriod) === 1 && 45 - Number(this.game.currentTime) <= Number(blockConfig.first)) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
          return true;
        }
        if (Number(this.game.currentPeriod) === 2 && 90 - Number(this.game.currentTime) <= Number(blockConfig.second)) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
          return true;
        }
      }
      if (this.game.sportId === 2) {
        blockConfig = this.blockSetting['hockey'];
        let remainTime = this.game.remainTime;
        const time = this.game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(this.game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
          return true;
        }
        if (Number(this.game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
          return true;
        }
        if (Number(this.game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
          return true;
        }
      }
      if (this.game.sportId === 3) {
        blockConfig = this.blockSetting['basketball'];
        let remainTime = this.game.remainTime;
        let currentPeriod = 1;
        const time = this.game.subResult?.split(' ');
        if (time) {
          remainTime = `00:${time[time.length - 1]}`;
          currentPeriod = time.reduce((cnt, element) => cnt + (element.includes('(')), 0);
          console.log(currentPeriod);
        }
        // console.log(remainTime)
        console.log(`남은시간: ${remainTime}`, `경기제한: ${this.game.isSuspended} 경기노출: ${this.game.isVisible}`, `남은경기시간없음: ${remainTime === '00:00:00'}`);
        if (remainTime === '00:00:00'){
          if (this.game.isSuspended === 'y' || this.game.isVisible === 'n') return true;
          if (currentPeriod >= 3) return;
        } else {
          if (currentPeriod === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
          if (currentPeriod === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
          if (currentPeriod === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
          if (currentPeriod === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
          if (currentPeriod > 4) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
        }
      }
      if (this.game.sportId === 5) {
        blockConfig = this.blockSetting['volleyball'];
        const name = `set${this.game.currentPeriod}Score`;
        const setHomeScore = this.game[name] ? this.game[name].split(':')[0] : 0;
        const setAwayScore = this.game[name] ? this.game[name].split(':')[1] : 0;
        if (Number(this.game.currentPeriod) === 1){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;
          if (Number(setHomeScore) >= Number(blockConfig.first) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.first) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
        }

        if (Number(this.game.currentPeriod) === 2){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.second) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.second) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
        }

        if (Number(this.game.currentPeriod) === 3){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.third) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.third) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
        }

        if (Number(this.game.currentPeriod) === 4){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.four) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.four) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
        }

        if (Number(this.game.currentPeriod) === 5){
          if (Number(setHomeScore) >= Number(blockConfig.five) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.five) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.game.id)
            return true;
          }
        }

      }
      if (this.gmae.sportId === 6) {
        blockConfig = this.blockSetting['americanFootball'];
        let remainTime = this.gmae.remainTime;
        const time = this.gmae.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(this.gmae.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.gmae.id)
          return true;
        }
        if (Number(this.gmae.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.gmae.id)
          return true;
        }
        if (Number(this.gmae.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.gmae.id)
          return true;
        }
        if (Number(this.gmae.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', this.gmae.id)
          return true;
        }
      }
      if (this.gmae.sportId === 11) {
        blockConfig = this.blockSetting['baseball'];
        const detailResult = this.gmae.subResult?.split(' ');
        const periodCnt = detailResult?.filter(e => e.indexOf('(') >= 0).length;
        if (periodCnt > Number(blockConfig.first)) return true;
      }

      return false;
    },

  }
}
</script>


<style scoped>
.w50 {
  width: 49% !important;
}
</style>
