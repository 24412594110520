<template>
  <!-- Layout -->
  <div>
    <router-view :currentMenu="currentMenu" ></router-view>
    <transition v-if="!setSite">
      <Overray></Overray>
    </transition>
  </div>
</template>

<script>
import '@/assets/css/m_mini_layout.css';

import Overray from '@/components/Overray';
import {mapState} from "vuex";
export default {
  name: "Layout",
  components: {
    Overray
  },
  data(){
    return {
      isScrollDown: false,
      currentMenu: '메인',
      authToken: this.$route.query.authToken,
      siteName: this.$route.query.siteName,
      setSite: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      version: state => state.version.version,
    }),
    socketConnect(){
      return this.$socket.connected;
    }
  },
  watch: {
    socketConnect(val){
      if (!val) {
        console.log('소켓연결', val)
        this.$socket.client.connect();
      }
    }
  },
  created() {
    setTimeout(()=> {this.setSite = true}, 2000)
    if (!this.siteName) alert('정상적인 경로로 접근해주세요');
    this.$store.dispatch('GET_SETTING', { siteName: this.siteName });
    if (this.authToken) {
      localStorage.token = JSON.stringify({ jwt_token: this.authToken });
      this.$store.dispatch('GET_SETTING', { siteName: this.siteName });
      this.$store.dispatch('ME')
    }
    // this.onResize();
    // this.displayCheck(this.$route)
  },
  mounted: function() {
    setTimeout(() => {
      if (this.user) this.checkLogin();
      this.interval = setInterval(() => {
        if (this.user) this.checkLogin();
      }, 15000);
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    checkLogin(){
      this.$store.dispatch('ME')
          .then((data) => {
            const result = data.data.payload;
            result.member.currentMenu = this.currentMenu;
            result.member.clientVersion = this.version;
          })
          // .catch((err) => {
          //   console.log(err);
          //   const params = {
          //     sitename: this.user.members_sitename,
          //     members_seq: this.user.members_seq,
          //     members_nickname: this.user.members_nickname,
          //     session: 'Layout.vue/checkLogin catch block',
          //     error: JSON.stringify(err)
          //   }
          //   this.$store.dispatch('ERROR_LOG', params)
          //   // this.$store.dispatch('LOGOUT')
          //   // this.$router.push({ path: '/main' })
          // })

      this.user.currentMenu = this.currentMenu;
      this.user.clientVersion = this.version;
      this.$socket.client.emit('setUser', { user: this.user })
    },
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
