<template>
<div class="main_content_continer" version="1.1.114">
    <section id="content" class="">
      <div class="wrapsport">
        <ul class="tab-list">
            <li >
              <router-link :to="{ name: '스포츠-인플레이(벳컨)'}">인플레이</router-link>
            </li>
            <li class="active">
              <router-link :to="{ name: '스포츠-유럽형(벳컨)'}">유럽형</router-link>
            </li>
            <li class="">
              <router-link :to="{ name: '스포츠-한국형(벳컨)'}">한국형</router-link>
            </li>
        </ul>
        <div class="wrapper" id="wrapper-hide">
          <!-- 퀵베팅 -->
          <div class="odds-setting-panel" style="display: none;">
            <div class="odds-setting-content">
              <div class="control-box">
                <div class="switch-content">
                  <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" disabled="">
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">퀵베팅</span>
                  </label>
                  <p class="icon-quick-betrule quickbet-btn"></p>
                  <div class="stick-on hide">
                    <div class="on">
                      <p>베팅금: 1,000</p>
                      <button class="confirm-edit">수정</button>
                    </div>
                    <div class="edit" style="display: none;">
                      <input type="text" placeholder="" disabled="">
                      <button class="confirm-amount">확인</button>
                    </div>
                  </div>
                </div>
                <a href="javascript: void(0)" class="icon-setting"></a>
                <div class="alarm odd-alarm hide">
                  <span class="icon-alarm"></span>
                <div>금액 수정후 베팅해주세요(단폴만 가능)</div>
                  </div>
              </div>
            </div>
          </div>
          <div class="odds-change-panel" style="display: none;">
              <div class="head">
                <p>배당율이 변경될 경우 :</p>
                <span class="icon-close close-change-panel"></span>
              </div>
              <div class="container">
                <div class="radio">
                  <input type="radio" id="radio-0" name="movement" value="AlwaysAsk">
                  <label for="radio-0" class="radio-label">항상 물어보기</label>
                </div>
                <div class="radio">
                  <input type="radio" id="radio-1" name="movement" value="HigherOdds">
                  <label for="radio-1" class="radio-label">더 높은 배당율로 수락</label>
                </div>
                <div class="radio">
                  <input type="radio" id="radio-2" name="movement" value="AnyOdds">
                  <label for="radio-2" class="radio-label">모든 배당율로 수락</label>
                </div>
              </div>
          </div>
          <Date :date-cnt="dateCnt" :init-folder="initFolder" :payload="payload"></Date>
          <SportZone :originList="originList" :filter-by-games="filterByGames"></SportZone>
          <!-- 카트 -->
            <transition name="fade">
                <div v-if="cartItem.length > 0" @click="toggleCart" id="open-cart" class="draggable" style="left: 312px; top: 675.2px;">
                    <div class=""></div>
                    <span>베팅카트</span>
                    <span class="odds">{{ odds }}</span><b> {{  cartItem.length }}</b>
                </div>
            </transition>
          <div id="tab-sport" class="tab-content-box sport-box sport-live">
            <CenterList v-if="lists" :origin-list="lists" :add-cart="addCart" :check-cart="checkCart" :select-game="selectGame" :selectedGame="selectedGame"></CenterList>
            <div></div>
          </div>
          <transition name="fade">
            <Cart v-if="openCart" :toggle-cart="toggleCart"></Cart>
          </transition>
          <transition name="fade">
            <CenterDetail v-if="selectedGame" :select-game="selectGame" :game="selectedGame" :add-cart="addCart" :check-cart="checkCart"></CenterDetail>
          </transition>
        </div>
      </div>
    </section>
</div>
</template>

<script>
import Date from '@/components/Game/Sports/European/Date'
import SportZone from '@/components/Game/Sports/European/SportZone'
import CenterList from '@/components/Game/Sports/European/CenterList'
import CenterDetail from '@/components/Game/Sports/European/CenterDetail'
import Cart from '@/components/Cart/EuropeanCart'

import moment from 'moment-timezone'
// import NewCart from '../../../../components/Betting/EuropeanCart'
// import Detail from '../../../../components/Game/Sports/European/Detail'
// import Date from '../../../../components/Game/Sports/European/Date'
// import MDate from '../../../../components/Game/Sports/European/MDate'
// import LeftSide from '../../../../components/Game/Sports/European/LeftSide'
// import MCart from '../../../../components/Betting/MEuropeanCart'
// import MPrice from '../../../../components/Game/Sports/Korean/MPrice'
// import Loading from '../../../../components/Loading'
// import Catogory from '../../../../components/Game/Sports/European/Catogory'
// import SelectedGame from '../../../../components/Game/Sports/European/SelectedGame'
// import RenewalDetail from '../../../../components/Game/Sports/European/RenewalEuropeanMobileDetail'

import MSG from '../../../../contants/msg'

import {mapState} from "vuex";

export default {
  name: "Sports",
  components: {
    Date,
    SportZone,
    CenterList,
    CenterDetail,
    Cart,
  },
  data: function () {
    return {
      type: 'european',
      category: null,
      isLoading: false,
      lists: null,
      sticky: false,
      openCart: false,
      payload: {
        date: moment().tz('Asia/Seoul').format('YYYY-MM-DD')
      },
      selectedGame: null,
    }
  },
  computed: {
    odds: function(){
      return Number(this.$store.getters["SPORT_TOTAL_ODDS"])
    },
    // 마감시간
    endTime: function () {
      return this.$store.state.setting.settings.config.option.gameEndtime.find(e => e.gameName === '스포츠').endTime
    },
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
    europeanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-유럽형').visible
    },
    ...mapState({
      originList: state => state.sports.originList,
      dateCnt: state => state.sports.dateCnt,
      user: state => state.user.user,
      cartItem: state => state.sports.cart,
    }),
  },
  mounted: function () {
    console.log('europeanDetail', this.game)
    if (!this.europeanVisible){
      this.$store.dispatch('SHOW_ERR_MSG3', '유럽형은 점검중 입니다.')
    }
    this.$store.dispatch('CLEAR_SPORT_CART', '프리매치(유럽형)')
    this.$store.dispatch('GET_DATE_CNT')
    this.initFolder(this.payload)
    this.interval = setInterval(this.removeGames, 1000)
  },
  beforeDestroy: function () {
    clearInterval(this.interval);
  },
  watch: {
    category: function(val){
      this.openDetailGame = [];
      if (!val) return this.lists = this.originList;
      if (Array.isArray(val)) {
        this.lists = this.originList.filter((g) => {
          return val.includes(g.sportId);
        })
        return;
      }
      this.lists = this.originList.filter(e => e.sportId === val);
    }
  },
  methods: {
    initFolder(payload){
      this.isLoading = false;
      this.$store.dispatch('GET_LIST', { payload }).then(() => {
        this.isLoading = true;
        this.lists = this.originList;
      })
    },
    /*
    * 종목의 자세히 버튼
    * **/
    checkArray: function (id) {
      let result = this.openDetailGame.indexOf(id) >= 0
      return result
    },

    /**
     * 토글 액션
     * */
    toggleDetail: function (id) {
      this.toggle(this.openDetailGame, id)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailGame.splice(index, 1);
      // return this.openDetailBet;
    },
    checkCart: function (bets) {
        let index = this.cartItem.find(item => item.betsSeq === bets.seq)
        return !!index;
    },
    // 시간이 지난 항목들 안보이게 설정
    removeGames: function () {
      if (this.lists && this.lists.length > 0) {
        let endEvents = this.lists.filter(e => this.$options.filters.gameStarted(e.startTime, this.endTime))
        endEvents.forEach((ele) => {
          let index = this.lists.indexOf(ele)
          if (index >= 0) {
            this.lists.splice(index, 1)
          }
        })
      }

      if (this.cartItem) {
        let endEvents = this.cartItem.filter(e => this.$options.filters.gameStarted(e.startTime, this.endTime))
        endEvents.forEach((ele) => {
          let index = this.cartItem.indexOf(ele)
          if (index >= 0) {
            this.$store.dispatch('DELETE_CART_ITEM_GAME', ele)
          }
        })
      }

    },
    addCart: function (bets, folders, game) {
      if (this.user){
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.유럽형.active) {
          return alert(MSG.authorization);
        }
      } else {
        return alert(MSG.isNotLogin);
      }
      if (bets.name === '') bets.name = bets.displayName;
      const cartItem = {
        gameId: game.id,
        gameSeq: game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: game.sportSeq,
        marketsId: folders.marketSeq,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueNameKr: game.league.nameKr,
        leagueNameEn: game.league.nameEn,
        leagueNameIcon: game.league.icon,
        sportNameKr: game.sport.nameKr,
        sportNameEn: game.sport.nameEn,
        sportNameIcon: game.sport.icon,
        homeNameKr: game.homeTeam.nameKr,
        homeNameEn: game.homeTeam.nameEn,
        awayNameKr: game.awayTeam.nameKr,
        awayNameEn: game.awayTeam.nameEn,
        marketNameKr: folders.market.nameKr,
        marketNameEn: folders.market.nameEn,
        startTime: game.startTime,
        odds1: null,
        odds2: null,
        odds3: null,
        betType: '프리매치(유럽형)',
        detailType: null,
        currentPeriod: game.currentPeriod,
        currentTime: game.currentTime,
        homeScore: game.homeResult,
        awayScore: game.awayResult,
        penalty: null,
        maxAmount: game.league?.maxAmount
      };

      if (folders.periodSequence !== '0') {
        cartItem.marketNameKr = folders.market.nameKr?.replace('{sw}', folders.periodSequence);
        cartItem.marketNameEn = folders.market.nameEn?.replace('{sw}', folders.periodSequence);
        if (folders.market.nameEn.indexOf('Player Points') >= 0 ||
            folders.market.nameEn.indexOf('Player Rebounds') >= 0 ||
            folders.market.nameEn.indexOf('Player Assists') >= 0 ||
            folders.market.nameEn.indexOf('Player Points and Assists') >= 0 ||
            folders.market.nameEn.indexOf('Player Points and Rebounds') >= 0 ||
            folders.market.nameEn.indexOf('Player Assists and Rebounds') >= 0 ||
            folders.market.nameEn.indexOf('Player Points, Assists and Rebounds') >= 0){
          const playerName = folders.name.split(')')[0];
          cartItem.marketNameKr = `${playerName}) ${cartItem.marketNameKr || cartItem.marketNameEn}`
          cartItem.marketNameEn = `${playerName}) ${cartItem.marketNameEn}`
        }

        if (
          folders.market.nameEn.indexOf('Player Total Kills') >= 0 ||
          folders.market.nameEn.indexOf('Player Total Assists') >= 0
        ){
          const playerName = folders.name.split(' ')[0];
          cartItem.marketNameKr = `${playerName} ${cartItem.marketNameKr || cartItem.marketNameEn}`
          cartItem.marketNameEn = `${playerName} ${cartItem.marketNameEn}`
        }
      }

      if (folders.market.nameEn?.includes('Player To Record a Hit')){
        cartItem.betsName = bets.displayName
      }

      if (cartItem.marketNameKr?.indexOf('1팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('1팀', cartItem.homeNameKr || cartItem.homeNameEn)
      }

      if (cartItem.marketNameKr?.indexOf('2팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('2팀', cartItem.awayNameKr || cartItem.awayNameEn)
      }

      this.$store.dispatch('ADD_EUROPEAN_CART_ITEM', cartItem)
    },
    selectGame(game){
      console.log(game, 'europeanSelctGame')
      this.selectedGame = game;
    },
    // 게임종목
    changeCategory: function (category) {
      this.category = category;
    },

    // 리그필터
    filterByGames: function (sortType, target) {
      this.openDetailGame = [];
      this.selectedGame = null;
      if (sortType === 'all') {
        return this.lists = this.originList;
      }

      if (sortType === 'league') {
        this.lists = this.originList.filter(e => e.leagueId === Number(target));
      }

      if (sortType === 'date') {
        this.lists = this.originList.filter(e => e.startTime.indexOf(target) >= 0);
      }

      if (sortType === 'sport') {
        this.lists = this.originList.filter(e => e.sportId === Number(target));
      }

      if (sortType === 'team') {
        this.lists = this.originList.filter(e =>
            e.homeTeam.nameEn.indexOf(target) >= 0 ||
            e.homeTeam.namekr.indexOf(target) >= 0 ||
            e.awayTeam.nameEn.indexOf(target) >= 0 ||
            e.awayTeam.namekr.indexOf(target) >= 0
        );
      }
    },
    toggleCart: function () {
      this.openCart = !this.openCart
    },
    toggleStick: function () {
      this.sticky = !this.sticky;
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
</style>
